import { Drawer } from '@mui/material'
import React, { useState } from 'react'

import { DrawerRegistroSelDate } from './DrawerRegistroSelDate.component';

/** ICONS */
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useEffect } from 'react';
import { GetTimes } from '../api/GetTimes.api';

export const DrawerRegistros = ({task, ModalLoadded, setModalLoadded,  setTexto}) => {
  

  const [RegistroTime, setRegistroTime] = useState([])
  const [Reload_, setReload_] = useState(0)
// console.log(task.id_work_orders_tasks);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    const GetRegisterNow = async () => {
      let registro_ = await GetTimes({codigo: task.id_work_orders_tasks})
      // console.log(registro_);
      if(registro_[0]?.initial_date){
        setRegistroTime({initial_date: registro_[0].initial_date, final_date:registro_[0].final_date});
      }
    }
    GetRegisterNow()
    return () => {
      GetRegisterNow()
    }
  }, [Reload_])
  

  return (
    <>
      <button className='btn btn-outline-primary btn-sm' onClick={()=>setState({ ...state, ['right']: true })}><ContentPasteIcon /> REGISTROS</button>
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        // id={task.id_task}
        // key={task.id_task}
      >
        <div className='container-crudmenu_2' style={{overflowY: 'scroll', height: '100vh'}}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 header-crud'>
                <div className='row py-2'>
                  <div className='col-2 text-center puntero' onClick={()=>setState({ ...state, ['right']: false })}>
                    <ArrowBackIosNewIcon color='info' />
                  </div>
                  <div className='col-10'>
                    <span>Tarea: {task.description}</span>
                  </div>
                </div>
              </div>
              <div className='col-12 mt-5 py-3' style={{borderBottom: '1px soliD #CACACA', borderTop: '1px soliD #CACACA'}}>
                <div className='row'>
                  <div className='col-12'>
                    <span className='h5'>{task?.user_assigned}</span>
                  </div>
                  <div className='col-12'>
                      <span style={{color: '#9D9D9D', paddingLeft: 10}}>
                      {RegistroTime?.initial_date&&(RegistroTime?.initial_date).split('T')[0].substring(0,10)} {RegistroTime?.initial_date&&(RegistroTime?.initial_date).split('T')[1].substring(0,8)}
                      <span className='px-2'>-</span> 
                      {RegistroTime?.final_date&&(RegistroTime?.final_date).split('T')[0].substring(0,10)} {RegistroTime?.final_date&&(RegistroTime?.final_date).split('T')[1].substring(0,8)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* <button className='btn btn-sm' onClick={()=>console.log(value_a,value_b)}>boton</button> */}
        <DrawerRegistroSelDate
          task={task}
          ModalLoadded={ModalLoadded}
          setModalLoadded={setModalLoadded}
          setTexto={setTexto}
          reload={Reload_}
          setReload_={setReload_} />
      </Drawer>
    </>
  )
}
