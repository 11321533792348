import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const ChangeCriterio = ({Timestamp, Fecha, Empresa, USUARIO, RUT, Codigo,ALM_CODE, Descripcion, Criterio_old, Criterio, StockMinimo, Justificacion, Consumo, correo}) => {

  // console.log({Timestamp, Fecha, Empresa, USUARIO, RUT, Codigo,ALM_CODE, Descripcion, Criterio_old, Criterio, StockMinimo, Justificacion, Consumo, correo});
  return new Promise(async (resolve, reject) => {
    
    let query = await fetch(URL_SRV_ARIZTIA+'noticambiocriterio',{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        EMPRESA: Empresa,
        USUARIO: USUARIO,
        RUT:RUT,
        CODIGO:Codigo,
        CORREO: correo,
        ALM_CODE,
        DESCRIPCION: Descripcion,
        CRITERIO_OLD: Criterio_old,
        CRITERIO: Criterio,
        STOCK_MINIMO:StockMinimo,
        JUSTIFICATIVO: Justificacion,
        TIMESTAMP:Timestamp,
        FECHA: Fecha,
        CONSUMO: Consumo
      })
    })
    let responsito = await query.json();
    // console.log(responsito);
    if(responsito.success){
      resolve(responsito.success)
    }else{
      resolve(null)
    }
  })
}
