import React, { useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { HeaderMenu } from '../../component/HeaderMenu'
import { GetOts } from '../../api/GetOts.api'
import LoginContext from '../../context/login_context/LoginContext';
import { GetTasks } from '../../api/GetTasks.api';
import { Badge, Box, Button, Drawer, IconButton, LinearProgress, MenuItem, Skeleton, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { IMaskInput } from 'react-imask';
import { DrawerRegistros } from '../../component/DrawerRegistros.component';
import { ModalLoaded } from '../../component/ModalLoaded.component';
import { GetSubTasks } from '../../api/GetSubTasks.api';
import { UpdateSubTask } from '../../api/UpdateSubTask.api';
import { CreaSolicitud } from '../../api/CreaSolicitud.api';
import { GetUserFracttal } from '../../api/GetUserFracttal.api';
import { GetResourceOT } from '../../api/GetResourceOT.api';
import { AddResouceH } from '../../api/AddResouceH.api';
import { DeleteResouceH } from '../../api/DeleteResouceH.api';
import { UploadFile } from '../../api/UploadFile.api';

/** icons */
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import { FaListCheck } from "react-icons/fa6";
import { GoTools } from "react-icons/go";
import { GoPaperclip } from "react-icons/go";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const TraducePriority = (priority) => {
  switch (priority) {
    case 'VERY_HIGH':
      return "MUY ALTA"
    case "HIGH":
      return "ALTA"
    case "MEDIUM":
      return "MEDIA"
    case "LOW":
      return "BAJA"
    case "VERY_LOW":
      return "MUY BAJA"
    default:
      return "MEDIA"
  } 
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #BFBFBF',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  [theme.breakpoints.up('sx')]: {
    minWidth: 0,
  },
  fontSize: 'x-small',
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  color: '#BFBFBF',
  padding: "0px 25px",
  margin: "0px 0px",
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    color: '#BFBFBF',
    opacity: 1,
  },
  '&.Mui-selected': {
    color: '#1890ff',
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const TaskList = () => {

	const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  const [TasksList, setTasksList] = useState([]);
  const [TasksListGrid, setTasksListGrid] = useState([]);
  const [ModalLoadded, setModalLoadded] = useState(false);

  const [CompleteTask, setCompleteTask] = useState(false);
  const [NegSubTasArr, setNegSubTasArr] = useState([]);
  const [NegSubTasArrBool, setNegSubTasArrBool] = useState(true);

  const [Texto, setTexto] = useState('');
  const [SubTasks, setSubTasks] = useState([]);
  const [TaskSeleccted, setTaskSeleccted] = useState([]);
  const [SubTaskEdit, setSubTaskEdit] = useState([]);
  const [SubTask_Negatives, setSubTask_Negatives] = useState([]);
  const [UserListFractal, setUserListFractal] = useState([]);
  const [UserListFractal_selected, setUserListFractal_selected] = useState('');
  const [MinutosRecurso, setMinutosRecurso] = useState(null);
  const [MinutosRecursocalc, setMinutosRecursocalc] = useState(0);
  const [ResourceList, setResourceList] = useState([]);
  const [CantTasks, setCantTasks] = useState(0);

  const [value, setValue] = React.useState(0);

  const ref = useRef(null);
  const inputRef = useRef(null);

  /** MUTADORES DE ESTADO DRAWER */
	const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if(CompleteTask === true && open === false){
      // console.log("Cerrar Drawer",CompleteTask , open);
      let confClose = window.confirm("Esta seguro de salir sin guardar los cambios?")
      if(confClose){
        setState({ ...state, [anchor]: open })
        setSubTaskEdit([])
      }
    }else{
      setState({ ...state, [anchor]: open });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /** obtiene las subtareas para listarlas */
  const MenuTask = async (task) => {
    await setTaskSeleccted(task);
    setModalLoadded(true)
    setTexto('Se estan Cargando las SubTareas.')

		// console.log("open menu Tasks", task.id_work_orders_tasks);
		const subTaskList = await GetSubTasks({subtask_id:task.id_work_orders_tasks})
    await setSubTasks(subTaskList);
    // console.log(subTaskList);// muestra por sonsola las subtareas como un array[]
    let tempBulk = []
    for (let frt = 0; frt < subTaskList.length; frt++) {
      const frt_ = subTaskList[frt];
      // console.log(frt_.id_task_form_item_type,frt_.value);
      if(frt_.id_task_form_item_type === 4 && frt_.value === null){
        // console.log('VERIFICACION');
        tempBulk.push({
          "id_work_orders_tasks_form_items":frt_.id_work_orders_tasks_form_items,
          "id_task_form_item_type": frt_.id_task_form_item_type,
          "value":'1'
        })
      }
      else if(frt_.id_task_form_item_type === 2 && frt_.value === null){
        // console.log('SI/NO');
        tempBulk.push({
          "id_work_orders_tasks_form_items":frt_.id_work_orders_tasks_form_items,
          "id_task_form_item_type": frt_.id_task_form_item_type,
          "value":'true'
        })
      }
      else {
        tempBulk.push({
          "id_work_orders_tasks_form_items":frt_.id_work_orders_tasks_form_items,
          "id_task_form_item_type": frt_.id_task_form_item_type,
          "value":frt_.value?frt_.value:""
        })
      }
    }
    MutadorSubtasks(tempBulk)
    // onChange={(e)=>MutadorSubtasks({
    //   ["id_work_orders_tasks_form_items"]:tsk.id_work_orders_tasks_form_items,
    //   ["value"]:e.target.value
    //   })}
		setState({ ...state, ['right']: !state.right });
    setModalLoadded(false)
	}
  const MutadorSubtasks = async (data) => {
    // console.log(data)
    let find_x_ = SubTaskEdit.filter(asx=>asx.id_work_orders_tasks_form_items!==data.id_work_orders_tasks_form_items)
    let concatenado = find_x_.concat(data)
    let ordenar = concatenado?.sort((x,y) => x.id_work_orders_tasks_form_items - y.id_work_orders_tasks_form_items)
    // console.log(ordenar)
    setSubTaskEdit(ordenar)
    // setValueMAK(ordenar)
  }

  /** ACTUALIZA LAS SUBTAREAS SELECCIONADAS y GENERA UNA SOLICITUD SI ESQUE ALGUNA DE LAS SUBTARES ES NEGATIVA */
  const HandleUpdateSubTask = async () => {

    setModalLoadded(true)
    setTexto('Se estan actualizando las SubTareas')

    // console.log(SubTaskEdit);
    if(NegSubTasArr.length === 0){
      // console.log('actualiza las subtareas');
      const update_ = await UpdateSubTask(SubTaskEdit) // ***************************************** ACTUALIZA LAS SUBTAREAS *****************************************
      setState({ ...state, ['right']: !state.right })
      setSubTaskEdit([])
      setModalLoadded(false)
    }else{

      setModalLoadded(true)
      setTexto('Se estan generando las solicitudes, favor no CIERRE o RECARGUE ESTA PAGINA.')
      // console.log('Hay Subtareas Negativas')
      
      if(NegSubTasArrBool){
        // console.log('Se creraran SOLICITUDES PARA '+NegSubTasArr.length+' Subtareas con Observaciones Negativas')
        for (let nstks = 0; nstks < NegSubTasArr.length; nstks++) {
          const nstks_ = NegSubTasArr[nstks];
          // console.log('archivo=>',nstks_.tsk_data.id_work_orders_tasks_form_items ,nstks_.file);
          // console.log({
          //   code:  task.code,// AGP LIGADO A LA TAREA
          //   description:"OBSERVACIONES: "+task.description,// OBSERVACIONES + NOMBREE DE LA TAREA
          //   comment:nstks_.tsk_data.description+":"+nstks_.comment+"\n"+"https://imagenname.apidev.info",// NEGATICO + OBSERVACION OBLIGATORIA
          //   name: task.user_assigned,// NOMBRE USUARIO LOGUEADO
          //   reference: task.wo_folio// NUMERO DE OT
          // })

          let u_file = ''//contiene la url de la imagen cargada y si no se carga imagen se devuelve vacia

          if (nstks_.file.name) {
            // console.log('hay archivo',nstks_.tsk_data.id_work_orders_tasks_form_items ,nstks_.file);
            u_file = await UploadFile({taskID:nstks_.tsk_data.id_work_orders_tasks_form_items , file:nstks_.file})
          }
          const update_ = await UpdateSubTask(SubTaskEdit) // ***************************************** ACTUALIZA LAS SUBTAREAS *****************************************
          const makeSoli = await CreaSolicitud({
            code:  TaskSeleccted.code,// AGP LIGADO A LA TAREA
            description:"OBSERVACIONES: "+TaskSeleccted.description,// OBSERVACIONES + NOMBREE DE LA TAREA
            comment:nstks_.tsk_data.description+":"+nstks_.comment+"\n"+u_file,// NEGATICO + OBSERVACION OBLIGATORIA
            name: TaskSeleccted.user_assigned,// NOMBRE USUARIO LOGUEADO
            reference: TaskSeleccted.wo_folio// NUMERO DE OT
          })

        }
        setSubTaskEdit([])
        setNegSubTasArr([])
        setState({ ...state, ['right']: !state.right })
        await setTexto(`Se han generando ${NegSubTasArr.length} solicitudes.`)
        await setTimeout(async () => {
          await setModalLoadded(false)            
        }, 2000);        
        
      }else{
        alert("Debe completar la observacion al final para continuar!!")
      }
    }
  }

  /** obtiene la lista de los usuarios de fractal */
  const Get_User_list = async () => {
    let listUserF = await GetUserFracttal(LoginState.planta)
    await setUserListFractal(listUserF)
  }

  /** OBTIENE LOS RECURSOS HUMANOS DE LA TAREA */
  const GetResorceHuman = async () => {
    let rchmn = await GetResourceOT(TaskSeleccted.wo_folio)
    setResourceList(rchmn)
  }

  /** AGREGA UN RECURSO HUMANO A LA TAREA */
  const AddresouseHuman = async (rut, minutos, wo_folio, taskID) => {
    // console.log(rut, minutos, wo_folio);
    let adresou = await AddResouceH({rut, minutos, wo_folio, taskID})
    // console.log(adresou);
    if(adresou){
      setUserListFractal_selected('')
      setMinutosRecurso(null)
      setMinutosRecursocalc('')
      await GetResorceHuman()
    }
    
  }

  const GetOtsList = async () => {
    setModalLoadded(true)
    setTexto('Se estan cargando las Tareas Pendientes, espere por favor!!')
    const ots = await GetOts({codigo:LoginState.rut})
		// console.log(ots);
    let hash = {}
    let baultOfTasks = []
    let baultOfTasks_grid = []
		// elimina las OT repetidas
		let elfiltro = await ots.filter(o =>  hash[o.wo_folio] ? false : hash[o.wo_folio] = true)
    // console.log(elfiltro);
    for (let otlst = 0; otlst < elfiltro.length; otlst++) {
      const otlst_ = elfiltro[otlst];
      // console.log(otlst_.wo_folio);
      const tasks = await GetTasks({wofolio:otlst_.wo_folio})
      console.log(tasks)
      for (let tsklst = 0; tsklst < tasks.length; tsklst++) {
        const {
          id_work_orders_tasks,
          done,
          wo_folio,
          items_log_description,
          description,
          costs_center_description,
          duration,
          tasks_log_types_description,
          tasks_log_types_2_description,
          date_maintenance,
          priorities_description
        } = tasks[tsklst];
        if(!done){
          baultOfTasks.push(
          {
            task:tasks[tsklst],
            id_work_orders_tasks,
            wo_folio,
            items_log_description,
            description,
            costs_center_description,
            duration,
            tasks_log_types_description,
            tasks_log_types_2_description,
            date_maintenance:String(date_maintenance).split('T')[0],
            priorities_description
          })
          baultOfTasks_grid.push(
            {
              "id": id_work_orders_tasks,
              "OT":wo_folio,
              "ACTIVO":items_log_description,
              "TAREA":description,
              "CECO":costs_center_description,
              "DURACION":duration,
              "TIPO_TAREA":tasks_log_types_description+"-"+tasks_log_types_2_description,
              "FECHA":String(date_maintenance).split('T')[0],
              "PRIORIDAD":priorities_description
            })
        }
        
      }
    }
    // console.log(baultOfTasks);
    await setTasksListGrid(baultOfTasks_grid)
    await setTasksList(baultOfTasks)
    await setCantTasks(baultOfTasks_grid.length)
    setModalLoadded(false)
    setTexto('')
  }

  /** AGREGA A UN ARRAY LAS SUBTAREAS NEGATIVAS */
  const AddNegativeTaskArr = async (is_tsk) => {
    // console.log(is_tsk);//setNegSubTasArr
    if(NegSubTasArr.length === 0){
      setNegSubTasArr([{
        id_wot_fid:is_tsk,
        comment:'',
        tsk_data: [],
        file: []
      }])
    }else{
      let find_tskNg = await NegSubTasArr.find(ts_k => ts_k.id_wot_fid === is_tsk)
      if(!find_tskNg)setNegSubTasArr([...NegSubTasArr,{
        id_wot_fid:is_tsk,
        comment:'',
        tsk_data: [],
        file: []
      }])
    }
  }

  /** QUITA AL ARRAY UNA SUBTAREAS NEGATIVA SEGUN ID */
  const SubbtractNegativeTaskArr = async (q_tsk) => {
    // console.log('QUITAR TASK', q_tsk);
    let f_negtv = await NegSubTasArr.filter(tsk => tsk.id_wot_fid !== q_tsk)
    await setNegSubTasArr(f_negtv)
  }

  /** AGREGA COMENTARIO Y ARCHIVO AL NEGATIVO */
  const AddParamsToNegativTask = async (id_tsk, data, tskdata, type) => {/******************************************************************************************************* */
    // console.log(id_tsk, data, tskdata, type);
    /** filtrar por el id el igual y apartarlo para modificarlo */
    let equal_ = await NegSubTasArr.filter(a=>a.id_wot_fid===id_tsk)
    let not_equal_ = await NegSubTasArr.filter(a=>a.id_wot_fid!==id_tsk)
    if(type === "comment"){
      equal_[0].tsk_data = tskdata
      equal_[0].comment = data
    }else if (type === "file"){
      equal_[0].tsk_data = tskdata
      equal_[0].file = data
    }
    setNegSubTasArr(equal_.concat(not_equal_))
    // console.log(equal_);
    /** flitar por el id los distintos para luego concatenarlo con el modificado y reemplazar el setNegSubTasArr(data) */
    /** luego al guardar enviar de uno por vez para crear solicitudes y enviar alertas de creacion de solicitudes */
  }

   /** metodos y logicas */
   const FormatMinutsTime = async (m) => {
    // console.log(m.includes(':'));
    setMinutosRecurso(m)
    if(m.includes(':')){
      let a = m.split(':')[0]
      let b = m.split(':')[1]
      // console.log((a*60)+(Number(b)));
      setMinutosRecursocalc((a*60)+(Number(b)))
    }
    if(m==='')setMinutosRecursocalc(0)
    // let a = m.split(':')[0]
    // let b = m.split(':')[1]
    // clg
  }

  const SortingTableByModule = async (column) => {
    let TasksListGrid__ = TasksListGrid
    await setTasksListGrid([])
    console.log(column);
    let ordenar1 = await TasksListGrid__.sort(function(a, b){
      if(a[column] < b[column]) { return -1; }
      if(a[column] > b[column]) { return 1; }
      return 0;
  })
    await setTasksListGrid(ordenar1)
    console.log(ordenar1);
    return
  }

  useEffect(() => {
    GetOtsList()
    return () => {
    }
  }, [])

  
  return (
    <>
      <HeaderMenu Pag_Sel={'tasks'} />
      <div>Tienes {CantTasks} tareas pendientes.</div>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 table-responsive'>
            <table className="table table-hover table-sm table-responsive-lg" style={{fontSize: 'small'}} >
              <thead>
                <tr>
                  <th onClick={()=>SortingTableByModule("OT")} >N° OT</th>
                  <th onClick={()=>SortingTableByModule("ACTIVO")}>ACTIVO</th>
                  <th onClick={()=>SortingTableByModule("TAREA")}>TAREA</th>
                  <th onClick={()=>SortingTableByModule("CECO")}>CECO</th>
                  <th onClick={()=>SortingTableByModule("DURACION")}>DURACION</th>
                  <th onClick={()=>SortingTableByModule("TIPO_TAREA")}>TIPO TAREA</th>
                  <th onClick={()=>SortingTableByModule("FECHA")}>FECHA</th>
                  <th onClick={()=>SortingTableByModule("PRIORIDAD")}>PRIORIDAD</th>
                </tr>
              </thead>
              <tbody>
                {/* {
                  TasksList.map((tskk, index)=> 
                    <tr key={tskk.id_work_orders_tasks} style={{cursor: 'pointer'}} onClick={()=>MenuTask(tskk.task)}>
                      <th scope="row">{tskk.wo_folio}</th>
                      <td>{tskk.items_log_description}</td>
                      <td>{tskk.description}</td>
                      <td>{tskk.costs_center_description}</td>
                      <td>{tskk.duration} seg.</td>
                      <td>{tskk.tasks_log_types_description}-{tskk.tasks_log_types_description}</td>
                      <td>{tskk.date_maintenance}</td>
                      <td>{ TraducePriority(tskk.priorities_description)}</td>
                    </tr>
                  )
                } */}
                
                {
                  TasksListGrid.map((tskk, index)=> 
                    <tr key={tskk.id} style={{cursor: 'pointer'}} onClick={()=>MenuTask(tskk.id)}>
                      <th scope="row">{tskk.OT}</th>
                      <td>{tskk.ACTIVO}</td>
                      <td>{tskk.TAREA}</td>
                      <td>{tskk.CECO}</td>
                      <td>{tskk.DURACION} seg.</td>
                      <td>{tskk.TIPO_TAREA}</td>
                      <td>{tskk.FECHA}</td>
                      <td>{ TraducePriority(tskk.PRIORIDAD)}</td>
                    </tr>
                  )
                }
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Draver */}
      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        id={TaskSeleccted.id_task}
        key={TaskSeleccted.id_task}
      >
        <div className='container-crudmenu_2 mb-5'>
        {/*  header menu crud */}
          <div className='shdowBan fixed-drud'>
            <div className='row header-crud py-2' style={{marginLeft: 1}}>
              <div className='col-1'>
                <ArrowBackIosIcon color='info' label="Volver" onClick={toggleDrawer('right', false)}/>
              </div>
              <div className='col-9 text-truncate'>
                {TaskSeleccted.items_log_description}
              </div>
              <div className='col-2'>
                {
                  ModalLoadded?<div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>:
                    <SaveIcon style={CompleteTask||NegSubTasArrBool?{color: "#1976d2", cursor: 'pointer'}:{color: "#ACACAC"}} 
                      onClick={CompleteTask||NegSubTasArrBool?()=>HandleUpdateSubTask():null}
                    />
                }
                
              </div>
            </div>
          </div>
          <div className='spacin-crud'></div>
          <div className='col'>
            <Box sx={{  }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                <AntTabs value={value} onChange={handleChange} aria-label="icon label tabs" centered>
                  <AntTab icon={<FaListCheck style={{fontSize: "x-large"}}/>} label="SUBTAREAS" {...a11yProps(0)} />
                  <AntTab icon={<GoTools style={{fontSize: "x-large"}}/>} label="RECURSOS HUMANOS" {...a11yProps(1)} />
                </AntTabs>
              </Box>

              {/* ********************* TAB DE SUBTAREA ********************* */}
              <CustomTabPanel value={value} index={0}>
                <div className='container-fluid mt-3 mb-4 p-0'>
                  <div className='row card-ot-crud-2 m-1 pt-2'>
                  {
                    SubTasks.map((tsk,index) =>(
                      tsk.id_task_form_item_type===1?
                        <div className='col-12 py-3' key={tsk.id_work_orders_tasks_form_items} style={{borderBottom: "1px solid #D5D3D3"}}>
                          <label style={{color: "gray"}}>{tsk.description}</label>
                          <TextField
                            fullWidth
                            size='small'
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            value={SubTaskEdit[index]?.value}
                            onChange={(e)=>MutadorSubtasks({
                              ["id_work_orders_tasks_form_items"]:tsk.id_work_orders_tasks_form_items,
                              ["id_task_form_item_type"]: tsk.id_task_form_item_type,
                              ["value"]:e.target.value
                              })}
                          />
                        </div>
                      : tsk.id_task_form_item_type===2?
                        <div className='col-12 py-3' key={tsk.id_work_orders_tasks_form_items} style={{borderBottom: "1px solid #D5D3D3"}}>
                          <label style={{color: "gray"}}>{tsk.description}</label>
                          <ToggleButtonGroup
                            fullWidth
                            exclusive
                            value={SubTaskEdit[index]?.value}
                            // onChange={handleAlignment}
                            // value={SubTaskEdit[index]?.value}
                            onChange={(e)=>MutadorSubtasks({
                              ["id_work_orders_tasks_form_items"]:tsk.id_work_orders_tasks_form_items,
                              ["id_task_form_item_type"]: tsk.id_task_form_item_type,
                              ["value"]:e.target.value
                              })}
                            defaultValue={"true"}
                          >
                            <ToggleButton
                              className={SubTaskEdit[index]?.value==="true"?'btn-green-active':'btn-green'}
                              onClick={()=>SubbtractNegativeTaskArr(tsk.id_work_orders_tasks_form_items)}
                              value="true">SI</ToggleButton>
                            <ToggleButton
                              className={SubTaskEdit[index]?.value==="false"?'btn-rojo-active':'btn-rojo'}
                              onClick={()=>AddNegativeTaskArr(tsk.id_work_orders_tasks_form_items)}
                              value="false">NO</ToggleButton>
                            <ToggleButton
                              className={SubTaskEdit[index]?.value==="N/A"?'btn-na-active':'btn-na'}
                              onClick={()=>SubbtractNegativeTaskArr(tsk.id_work_orders_tasks_form_items)}
                              value="N/A">N/A</ToggleButton>
                          </ToggleButtonGroup>
                          {
                            SubTaskEdit[index]?.value === 'false'?
                            <div className='col-12 p-0 m-0'>
                              <div className='row'>
                                <div className='col-10'>
                                  <label style={{color: "rgb(255,0,0"}}>OBSERVACION PARA CREAR SOLICITUD AUTOMATICA*</label>
                                  <TextField
                                    color='error'
                                    focused
                                    fullWidth
                                    size='small'
                                    id="outlined-multiline-static-1"
                                    multiline
                                    rows={3}
                                    onChange={(e)=>AddParamsToNegativTask(tsk.id_work_orders_tasks_form_items,e.target.value,tsk,'comment')}
                                  />
                                </div>
                                <div className='col-2 center-v pt-5'>
                                  <Button component="label" color='secondary' variant="contained"><AttachmentIcon />
                                    <VisuallyHiddenInput
                                      accept='image/jpeg'
                                      type="file"
                                      onChange={(e)=>{AddParamsToNegativTask(tsk.id_work_orders_tasks_form_items,e.target.files[0],tsk,'file')}}
                                    />
                                  </Button>
                                  <small style={{color:'#CACACA'}}>Max. 2MB</small>
                                </div>
                              </div>
                            </div>:null
                          }
                        </div>
                      : tsk.id_task_form_item_type===3?
                        <div className='col-12 py-3' key={tsk.id_work_orders_tasks_form_items} style={{borderBottom: "1px solid #D5D3D3"}}>
                          <label style={{color: "gray"}}>{tsk.description}</label>
                          <TextField
                            fullWidth
                            size='small'
                            id="outlined-multiline-static"
                            type='number'
                            value={SubTaskEdit[index]?.value}
                            onChange={(e)=>MutadorSubtasks({
                              ["id_work_orders_tasks_form_items"]:tsk.id_work_orders_tasks_form_items,
                              ["id_task_form_item_type"]: tsk.id_task_form_item_type,
                              ["value"]:e.target.value
                              })}
                          />
                        </div>
                      :<div className='col-12 py-3' key={tsk.id_work_orders_tasks_form_items} style={{borderBottom: "1px solid #D5D3D3"}}>
                        <label style={{color: "gray"}}>{tsk.description}</label>
                        <ToggleButtonGroup
                          fullWidth
                          exclusive
                          value={SubTaskEdit[index]?.value}
                          onChange={(e)=>MutadorSubtasks({
                            ["id_work_orders_tasks_form_items"]:tsk.id_work_orders_tasks_form_items,
                            ["id_task_form_item_type"]: tsk.id_task_form_item_type,
                            ["value"]:e.target.value
                            })}
                            defaultValue={"1"}
                        >
                          <ToggleButton
                            className={SubTaskEdit[index]?.value==="1"?'btn-green-active':'btn-green'}
                            onClick={()=>SubbtractNegativeTaskArr(tsk.id_work_orders_tasks_form_items)}
                            value="1">APROBÓ</ToggleButton>
                          <ToggleButton
                            className={SubTaskEdit[index]?.value==="2"?'btn-naranjo-active':'btn-naranjo'}
                            onClick={()=>AddNegativeTaskArr(tsk.id_work_orders_tasks_form_items)}
                            value="2">ALERTA</ToggleButton>
                          <ToggleButton
                            className={SubTaskEdit[index]?.value==="3"?'btn-rojo-active':'btn-rojo'}
                            onClick={()=>AddNegativeTaskArr(tsk.id_work_orders_tasks_form_items)}
                            value="3">FALLÓ</ToggleButton>
                        </ToggleButtonGroup>
                        {
                            SubTaskEdit[index]?.value === '2' || SubTaskEdit[index]?.value === '3'?
                            <div className='col-12 p-0 m-0'>
                              <div className='row'>
                                <div className='col-10'>
                                  <label style={{color: "rgb(255,0,0"}}>OBSERVACION PARA CREAR SOLICITUD AUTOMATICA*</label>
                                  <TextField
                                    color='error'
                                    focused
                                    fullWidth
                                    size='small'
                                    id="outlined-multiline-static-2"
                                    multiline
                                    rows={3}
                                    onChange={(e)=>AddParamsToNegativTask(tsk.id_work_orders_tasks_form_items,e.target.value,tsk,'comment')}
                                  />
                                </div>
                                <div className='col-2 center-v pt-5'>
                                  <Button component="label" color='secondary' variant="contained"><AttachmentIcon />
                                    <VisuallyHiddenInput size={1500} accept='image/jpeg' type="file" onChange={(e)=>AddParamsToNegativTask(tsk.id_work_orders_tasks_form_items,e.target.files[0],tsk,'file')} />
                                  </Button>
                                  <small style={{color:'#CACACA'}}>Max. 2MB</small>
                                </div>
                              </div>
                            </div>:null
                          }
                      </div>
                    ))
                  }
                    
                  </div>
                </div>
              </CustomTabPanel>
              {/* TAB DE RECURSOS */}
              <CustomTabPanel value={value} index={1}>
              <div className='container-fluid mt-3 mb-4 p-0'>
                <div className='row card-ot-crud-2 m-1 pt-2'>
                  <div className='col-12' style={{borderBottom: '3px solid #CACACA', marginBottom: '15px'}}>
                  <Typography>RECURSOS HUMANOS</Typography>
                  {
                    ResourceList&&
                    ResourceList.length>=1?
                      <table className="table table-sm" style={{fontSize: 'small'}}>
                      {/* {console.log(ResourceList)} */}
                        <thead>
                          <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Tiempo</th>
                            {/* <th scope="col">Accion</th> */}
                          </tr>
                        </thead>
                        <tbody>
                        {
                          ResourceList.map(rst => (
                            <tr key={rst.id_wo_resource}>
                              <td>{rst.source_description}</td>
                              <td>{rst.qty} hrs</td>
                              {/* <td className='text-center'>
                                <DeleteIcon className='' color='error' onClick={()=>DeleteResource({folio:rst.wo_folio,id_wo_task: rst.id_work_orders_tasks, id_wo_resource: rst.id_wo_resource})} />
                              </td> */}
                            </tr>
                          ))
                        }
                          
                        </tbody>
                      </table>:<span>Sin Recursos</span>
                  }
                  </div>
                  <div className='col-12' style={{border: '1px '}}>
                    <span>SELECCIONE RECURSOS HUMANO</span>
                    <div className='row'>
                      <div className='col-12 mb-2 mt-3'>
                        <TextField
                          fullWidth
                          size='small'
                          id="outlined-select-currency"
                          select
                          label="RECURSO HUMANO"
                          value={UserListFractal_selected}
                          onChange={(e)=>setUserListFractal_selected(e.target.value)}
                          // helperText="Please select your currency"
                        >
                          {UserListFractal.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className='col-12 my-3'>
                        <label style={{position: 'absolute', bottom: 22, marginLeft: 10, backgroundColor: 'white', padding: '0px 5px 0px 5px'}}><small>TIEMPO DE EJECUCION</small></label>
                        <IMaskInput
                          style={{borderRadius:5, border: '1px solid #CACACA', width: '100%', padding: '10px 5px 5px 10px'}}
                          mask="000:00"
                          radix="."
                          value={MinutosRecurso}
                          unmask={'typed'} // true|false|'typed'
                          ref={ref}
                          inputRef={inputRef}  // access to nested input
                          // DO NOT USE onChange TO HANDLE CHANGES!
                          // USE onAccept INSTEAD
                          onAccept={
                            // depending on prop above first argument is
                            // `value` if `unmask=false`,
                            // `unmaskedValue` if `unmask=true`,
                            // `typedValue` if `unmask='typed'`
                            (value, mask) => FormatMinutsTime(mask._value)
                          }
                          // ...and more mask props in a guide

                          // input props also available
                          placeholder='HHH:MM'
                        />
                         
                      </div>
                      <div className='col-12 my-2'>
                        <button
                          disabled={UserListFractal_selected!==''&&MinutosRecursocalc>=1?false:true}
                          className='btn btn-success btn-sm' onClick={()=>AddresouseHuman(UserListFractal_selected,MinutosRecursocalc, TaskSeleccted.wo_folio, TaskSeleccted.id_work_orders_tasks)}>AGREGAR RECURSO</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </CustomTabPanel>
            </Box>
          </div>
        </div>
        <div className='fixed-footer text-right p-2'>
          <DrawerRegistros task={TaskSeleccted} ModalLoadded={ModalLoadded} setModalLoadded={setModalLoadded} setTexto={setTexto} />
        </div>
      </Drawer>
{/* Draver */}

      {
				ModalLoadded?<ModalLoaded texto={Texto} />:null
			}
    </>
  )
}
