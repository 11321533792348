import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import LoginContext from '../../context/login_context/LoginContext';
import { GeltListCriterios } from '../../api/criterio/GeltListCriterios.api';
import { ConfirmChange } from '../../api/criterio/ConfirmChange.api';
import { ModalLoaded } from '../../component/ModalLoaded.component';

/** icons */
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

export const CrudCriticidad = () => {

  const [CriticList, setCriticList] = useState([]);
  // modal loaded
  const [ModalLoadded, setModalLoadded] = useState(false);
	const [Texto, setTexto] = useState('');

  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  // console.log(LoginState);

  const ObtenerListaDeCriterios = async () => {
    await setCriticList(await GeltListCriterios({EMPRESA:LoginState.planta}))
  }

  const ConfimaElCambioDeCriterio = async (id) => {
    // console.log(id);
    setTexto('Se estan Modificando los estados de la solicitud, espere por favor.')
		await setModalLoadded(true)
    await ConfirmChange({ID:id})
    await ObtenerListaDeCriterios()
    await setModalLoadded(false)
  }

  useEffect(() => {
    ObtenerListaDeCriterios()
    return () => {
      
    }
  }, [])
  
  return (
    <div>
      <HeaderMenu Pag_Sel={'crudticidades'} />
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 text-right'>
            <span style={{cursor: 'pointer'}} onClick={()=>ObtenerListaDeCriterios()} title='Volver a Cargar los Criterios.'>
              <RotateLeftIcon color='info' />
            </span>
          </div>
          <div className='col'>
            <table className="table table-sm text-center" style={{fontSize:"small"}}>
              <thead>
                <tr>
                  <th scope="col">Codigo</th>
                  <th scope="col">Fecha</th>
                  <th scope="col">Descripcion</th>
                  <th scope="col">Criterio Old</th>
                  <th scope="col">Criterio New</th>
                  <th scope="col">Solicitante</th>
                  <th scope="col">Accion</th>
                </tr>
              </thead>
              <tbody>
                {
                  CriticList.map(crtl =><tr key={crtl._id}>
                    <th scope="row">{crtl.CODIGO}</th>
                    <td>{crtl.FECHA}</td>
                    <td>{crtl.DESCRIPCION}</td>
                    <td>{crtl.CRITERIO_OLD}</td>
                    <td>{crtl.CRITERIO_NEW}</td>
                    <td>{crtl.USUARIO}</td>
                    <td>
                      <span
                        title='Enviar Confirmacion por criterio Cambiado.'
                        style={{cursor: 'pointer'}}
                        onClick={crtl.ISAUTIZADA?null:()=>ConfimaElCambioDeCriterio(crtl._id)}>
                        <MarkEmailReadIcon color={crtl.ISAUTIZADA?'disabled':'success'}/>
                      </span>
                    </td>
                  </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {
				 ModalLoadded?<ModalLoaded texto={Texto} />:null
			}
    </div>
  )
}
