import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Drawer } from '@mui/material';
import LoginContext from '../context/login_context/LoginContext';

import MenuIcon from '@mui/icons-material/Menu';
import OutputOutlinedIcon from '@mui/icons-material/OutputOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

import './main.css'

import dayjs from 'dayjs'
import { VERSION_APP } from '../router/Url';

var weekOfYear = require('dayjs/plugin/weekOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(weekOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs().tz('America/Santiago')

let RCambioCriterio = [
  "16114499-1",//MURRUTIA
  "17687529-1",//VMATURANA
  "18213587-9",//DSILVA
  "16520167-1",//ABARRERA
  "17049061-4",//PEGUTIERREZ
  "19071902-2",//CMEZA
  "panolsemapai@ariztia.com",//PANOLSEMAPAI
  "26.489.095-0",//MCRUZF
  "18.693.447-4",//fareyes
  "pmantencionarica@ariztia.com",//PAÑOL MANTENCION ARICA
  "19684645-K",//ANDRES MELILLAN
]

export const HeaderMenu = ({Pag_Sel,VisibleModal, setVisibleModal}) => {
  const navigate = useNavigate();
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [PageSelcected, setPageSelcected] = useState(Pag_Sel)
  const [IsHiddedn, setIsHiddedn] = useState(false);
  // console.log(LoginState);
  
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleCloseSession = async () => {
    CloseLogin()
    navigate('/')
  }

  const Make_A_File_whit_all_Data = async () => {
    // await MakeMeAFile({PLANTA:LoginState.planta})
    setVisibleModal(true)
    // console.log('make method to make a one file report')
  }

  useEffect(() => {
    // const a = async () => {
    //   console.log( LoginState)
    // }
    // a()
  }, [])
  


  return (
    <>
     <div className='container-fluid header mb-2'>
        <div className='row'>

          <div className='col-12'>
            <div className='row'>
                  
              <div className="ocean_2">
                <div className="wave_2"></div>
                <div className="wave_2"></div>
                <div className="wave_2"></div>
              </div>
              <div className='col-3 my-2'>
                <img src='/favicon.png' className='puntero' height={'50px'} onClick={()=>navigate('/home')}/>
              </div>
              <div className='col-9 p-auto m-auto'>
                <div className='row'>
                  <div className='col-10'>
                    <div className='row'>
                      <div className='col-12 hidden-xs col-md-6'>
                        <b>{LoginState.planta}</b>
                      </div>
                      <div className='col-12 col-md-6 text-right'>
                        {LoginState.user}
                      </div>
                    </div>
                  </div>
                  <div className='col-2 text-right p-auto m-auto'>
                    <span className='menubtn' onClick={toggleDrawer('right', true)}><MenuIcon/></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        <div className='menu-container' style={{height: '100vh', backgroundColor: 'green'}}>
            <img src='/favicon.png' style={{height: "75px", margin: '15px'}} className='px-5 ml-4' />
            <hr/>
            {/*   */}
            {/* <div>
              <p className='menu-item selected-item'><PlagiarismOutlinedIcon className='color-icon-menu'/>lista de Acciones</p>
            </div> */}
            
            
            <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='solicitudes'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/solicitudes')}><PendingActionsIcon className='color-icon-menu'/><span className='spacing'></span> Solicitud Trabajo</p>
            </div>

            <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='ot'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/ot')}><PendingActionsIcon className='color-icon-menu'/><span className='spacing'></span> Orden de Trabajo</p>
            </div>

            {/* <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='tasks'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/tasks')}><PlaylistAddCheckIcon className='color-icon-menu'/><span className='spacing'></span> Tareas Pendientes</p>
            </div> */}

            <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='entrega_turno'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/home')}><FormatListNumberedIcon className='color-icon-menu'/><span className='spacing'></span> Ingreso de O.T.C</p>
            </div>
            {
              RCambioCriterio.includes(LoginState.rut)?<div className='' onClick={toggleDrawer('right', false)}>
                <p className={PageSelcected==='cambiocritico'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/cambiocritico')}><FlipCameraAndroidIcon className='color-icon-menu'/><span className='spacing'></span> Cambio de Criticidad</p>
              </div>:null
            }
            
            {
              LoginState.rut==="16114499-1"?<>
                <div className='' onClick={toggleDrawer('right', false)}>
                  <p className={PageSelcected==='crudticidades'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/crudticidades')}><PublishedWithChangesIcon className='color-icon-menu'/><span className='spacing'></span> Crud Criticidad</p>
                </div>
                <div className='' onClick={toggleDrawer('right', false)}>
                  <p className={PageSelcected==='crudr'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/crudr')}><QrCodeScannerIcon className='color-icon-menu'/><span className='spacing'></span> Crud QR</p>
                </div>
                <div className='' onClick={toggleDrawer('right', false)}>
                  <p className={PageSelcected==='entregaturno'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/entregaturno')}><PendingActionsIcon className='color-icon-menu'/><span className='spacing'></span> Libro Turno</p>
                </div>
              </>:null
            }
            

            {/* <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='pdf'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/pdf')}><FormatListNumberedIcon className='color-icon-menu'/><span className='spacing'></span> Pdf ot</p>
            </div> */}

            {/* <div className='' onClick={toggleDrawer('right', false)}>
              <p className={PageSelcected==='st'?'menu-item selected-item':'menu-item'} onClick={()=>navigate('/st')}><FactCheckOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Solicitud de Trabajo</p>
            </div> */}
  
            {/* <div className='' onClick={toggleDrawer('right', false)}>
              <p className='menu-item' onClick={()=>Make_A_File_whit_all_Data()}><MoveToInboxIcon className='color-icon-menu'/><span className='spacing'></span> Descargar Reporte</p>
            </div> */}

            

            <hr/>
            <p className='menu-item' onClick={()=>HandleCloseSession()}>
              <OutputOutlinedIcon className='color-icon-menu'/><span className='spacing'></span> Salir
            </p>
            <div className='menu-footer-version'>
              <small>{VERSION_APP}</small>
            </div>
        </div>
      </Drawer>

    </>
  )
}


/**
 *  <svg width="30" height="30" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="7.5" cy="7" rx="7.5" ry="7" fill="white"/>
      <circle cx="7" cy="7" r="4.5" fill="white" stroke="#717171"/>
      <path d="M7.875 9.0625C7.875 9.57944 7.45444 10 6.9375 10C6.42056 10 6 9.57944 6 9.0625C6 8.54556 6.42056 8.125 6.9375 8.125C7.45444 8.125 7.875 8.54556 7.875 9.0625ZM6.10852 4.2953L6.26789 7.4828C6.27538 7.63248 6.39892 7.75 6.54879 7.75H7.32621C7.47608 7.75 7.59962 7.63248 7.60711 7.4828L7.76648 4.2953C7.77451 4.13465 7.64644 4 7.48559 4H6.38941C6.22856 4 6.10049 4.13465 6.10852 4.2953Z" fill="#717171"/>
      <path d="M11.0115 7.24036L10.0139 6.24278C10.0139 6.24278 10.0139 6.24278 10.0139 6.24277C9.81154 6.0404 9.48342 6.0404 9.28105 6.24278C9.28105 6.24278 9.28105 6.24278 9.28105 6.24278L8.90177 6.62207L8.90177 6.62208C8.69942 6.82445 8.6994 7.15255 8.90177 7.35492L10.6451 9.09821C10.8474 9.3006 11.1755 9.30059 11.3779 9.09824L11.3779 9.09823L14.4622 6.01391L14.4622 6.01391C14.6646 5.81154 14.6646 5.48344 14.4622 5.28107L14.0829 4.90178L14.0829 4.90177C13.8806 4.69941 13.5525 4.69941 13.3501 4.90177L11.0115 7.24036Z" fill="#717171" stroke="white" stroke-width="0.5"/>
    </svg>

    <svg width="1" height="17" viewBox="0 0 1 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.25" x2="0.25" y2="17" stroke="#717171" stroke-width="0.5"/>
    </svg>
 */