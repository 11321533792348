import { URL_CREA_SOLI } from "../router/Url";


export const CreaSolicitud = async (data) => {

  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_CREA_SOLI,{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type_1: data?.type_1||null,
        code: data.code,
        description: data.description,
        comment: data.comment,
        name: data.name,
        reference: data.reference,
        priority: data?.is_urgent||null
      })
    })
    let responsito = await query.json();
    console.log(responsito.data[0]);
    if(responsito.success){
      resolve(responsito.success)
    }else{
      if(responsito.message === "500")resolve(alert('Error de servidor, Intentelo mas tarde'))
      if(responsito.message === "404")resolve(alert('Error de servidor, Intentelo mas tarde'))
    }
  })
 
}

// response de la solicitud creada
// {
//   "success": true,
//   "message": "200",
//   "data": [
//       {
//           "id": 13984,
//           "id_company": 705,
//           "id_type": null,
//           "id_item": 4184,
//           "is_urgent": false,
//           "description": "OBSERVACIONES: ot de prueva no tomar en consideracion",
//           "id_account": null,
//           "date": "2024-01-26T14:54:16.431385+00:00",
//           "rating": null,
//           "rating_notes": null,
//           "observation": "ANERA: DESCRIPCION UNO:NO TOMAR EN CONSIDERACION\n",
//           "date_incident": "2024-01-26T14:57:33+00:00",
//           "date_solution": null,
//           "identifier": "OT221727",
//           "requested_by": "MARCO ANTONIO URRUTIA MOLINA",
//           "geolocation": null,
//           "date_maintenance": null,
//           "email_requested_by": null,
//           "from_guest_portal": false,
//           "items_description": null
//       }
//   ],
//   "total": 0
// }

// {
//   "success": true,
//   "message": "200",
//   "data": [
//       {
//           "id": 13987,
//           "id_company": 705,
//           "id_type": null,
//           "id_item": 21,
//           "is_urgent": false,
//           "description": "OBSERVACIONES: OP DE PRUEBA NO TOMAR EN CONSIDERACION",
//           "id_account": null,
//           "date": "2024-01-26T15:04:56.430298+00:00",
//           "rating": null,
//           "rating_notes": null,
//           "observation": "ANERA: DESCRIPCION 1:no tomar en consideracion\n",
//           "date_incident": "2024-01-26T15:08:13+00:00",
//           "date_solution": null,
//           "identifier": "OT221727",
//           "requested_by": "MARCO ANTONIO URRUTIA MOLINA",
//           "geolocation": null,
//           "date_maintenance": null,
//           "email_requested_by": null,
//           "from_guest_portal": false,
//           "items_description": null
//       }
//   ],
//   "total": 0
// }