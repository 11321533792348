import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import { InputAdornment, MenuItem, TextField } from '@mui/material'
import { ObtainDataCode } from '../../api/criterio/ObtainDataCode.api'
import { ChangeCriterio } from '../../api/criterio/ChangeCriterio.api'
import LoginContext from '../../context/login_context/LoginContext'
import { ModalLoaded } from '../../component/ModalLoaded.component'

let critrion_list = [
  {
    value: 'CRITICO',
    label: 'CRITICO',
  },
  {
    value: 'CONSUMIBLE',
    label: 'CONSUMIBLE',
  },
  {
    value: 'A PEDIDO',
    label: 'A PEDIDO',
  },
  {
    value: 'OBSOLETO',
    label: 'OBSOLETO',
  },
]

export const CambioCriticidad = () => {

  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  // console.log(LoginState);
  const [Codigo, setCodigo] = useState('');
  const [Descripcion, setDescripcion] = useState('');
  const [Criterio_old, setCriterio_old] = useState('');
  const [Criterio, setCriterio] = useState('');
  const [Consumo, setConsumo] = useState('');
  const [StockMinimo, setStockMinimo] = useState('');
  const [Justificacion, setJustificacion] = useState('');

  const [IsComplete, setIsComplete] = useState(true);
  const [IsFoundCode, setIsFoundCode] = useState(false);

  const [PlantAlmacen, setPlantAlmacen] = useState('')

  // modal loaded
  const [ModalLoadded, setModalLoadded] = useState(false);
	const [Texto, setTexto] = useState('');

  // OBTINE LOS DATOS DEL CODIGO INGRESADO
  const ObtaiDataToCode = async (code) => {
    
    let cosigoFormatPlanta = PlantAlmacen+code
    let dataCode = await ObtainDataCode({CODIGO: cosigoFormatPlanta});
    if(dataCode === null){
      setIsFoundCode(false)
      setDescripcion('')
      setCriterio_old('')
      //alert("El Codigo ingresado no se encuentra en nuestras bases de datos, por favor verifiquelo.")
    }else{
      setIsFoundCode(true)
      setDescripcion(dataCode.DESC_CODIGO)
      setCriterio_old(dataCode.CRITICIDAD)
    }
    // console.log(dataCode);
  }
  
  // ENVIA LOS DATOS PARA GENERAR LA AUTORIZACION DE CAMBIO DE CRITERIO
  const SendTataToChangeCriter = async () => {
    setTexto('Se esta enviando la solicitud, por favor espere.')
		await setModalLoadded(true)
    setIsComplete(true)
    let cambioEl = await ChangeCriterio({
      Timestamp:(new Date().getTime()/1000).toFixed(0),
      Fecha:new Date().toLocaleString(),
      Empresa: LoginState.planta,
      USUARIO: LoginState.user,
      RUT: LoginState.rut,
      ALM_CODE: PlantAlmacen+Codigo,
      correo: LoginState.mail,
      Codigo,
      Descripcion,
      Criterio_old,
      Criterio,
      StockMinimo,
      Justificacion,
      Consumo
    })
    if(cambioEl){
      // alert("La solicitud de Cambio de Criterio Fue Enviada para su APROBACION, se le INFORMARA por CORREO.")
      setIsFoundCode(false)
      setCodigo('');
      setDescripcion('');
      setCriterio_old('');
      setCriterio('');
      setConsumo('');
      setStockMinimo('');
      setJustificacion('');
      setPlantAlmacen('');
    }
    await setModalLoadded(false)
  }

  useEffect(() => {
    setStockMinimo('')
    return () => {
    }
  }, [Criterio])
  
  useEffect(() => {
    if(Codigo !== '' && Codigo.length >= 5 && Consumo !== '' && Criterio !== '' && Justificacion !== '' && Justificacion.length >= 10){
      setIsComplete(false);
      if(Criterio === "CRITICO" || Criterio === "CONSUMIBLE"){
        if(StockMinimo === ''){
          setIsComplete(true);
        }
      }
    }else{
      setIsComplete(true);
    }
    return () => {
    }
  }, [Codigo, Criterio, Justificacion, StockMinimo, Consumo])

  useEffect(() => {
    // LoginState.planta
    switch (LoginState.planta) {
      case "PAICO":
        setPlantAlmacen("15.128_")
        break
      case "OCHAGAVIA":
        setPlantAlmacen("7.130_")
        break
      case "ARICA":
        setPlantAlmacen("60.66100_")
        break
        
    }
    return () => {
    }
  }, [])
  
  

  return (
    <div>
      <HeaderMenu Pag_Sel={'cambiocritico'} />
      <div className='container'>
        <div className='row'>
          <div className='col-12 my-2'>
            <span className='lead'><b>Solicitud de Cambio de Criterio</b></span>
          </div>
          <div className='col-12 col-md-6 my-2'>
            <TextField fullWidth id="outlined-basic" size='small' onBlur={(e)=>ObtaiDataToCode(e.target.value)} value={Codigo} onChange={(e)=>setCodigo(e.target.value)} label="Codigo Articulo" variant="outlined" />
          </div>
          <div className='col-12 col-md-6 my-2'>
            <TextField fullWidth id="outlined-basic" size='small' value={Descripcion} onChange={(e)=>setDescripcion(e.target.value.toUpperCase())} disabled={IsFoundCode} label="Descripcion" variant="outlined" />
          </div>
          <div className='col-12 col-md-6 my-2'>
            <TextField fullWidth id="outlined-basic" size='small' value={Criterio_old===''?'SIN CRITERIO ASIGNADO':Criterio_old} disabled label="Criterio Actual" variant="outlined" />
          </div>
          
          <div className='col-12 col-md-6 my-2'>
            <TextField
              fullWidth
              id="outlined-select-currency"
              size='small'
              select
              label="Criterio Nuevo"
              value={Criterio}
              onChange={(e)=>setCriterio(e.target.value)}
              // defaultValue="EUR"
              // helperText="Please select your currency"
            >
              {critrion_list.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className='col-12 col-md-6 p-0'>
            {
              Criterio==="CONSUMIBLE"?<div className='col-12 my-2'>
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position="start">Unidades</InputAdornment>,
                  }}
                  fullWidth
                  id="outlined-basic"
                  type='number'
                  value={StockMinimo}
                  onChange={(e)=>setStockMinimo(e.target.value)}
                  size='small'
                  label="Stock Minimo segun Rotacion"
                  variant="outlined" />
              </div>:
              Criterio==="CRITICO"?<div className='col-12 my-2'>
                <TextField
                  InputProps={{
                    endAdornment: <InputAdornment position="start">Unidades</InputAdornment>,
                  }}
                  fullWidth
                  id="outlined-basic"
                  type='number'
                  value={StockMinimo}
                  onChange={(e)=>setStockMinimo(e.target.value)}
                  size='small'
                  label="Stock Minimo segun Catalogo"
                  variant="outlined" />
              </div>:null
            }
            <div className='col-12 my-2'>
              <TextField
                fullWidth id="outlined-basic"
                InputProps={{
                  endAdornment: <InputAdornment position="start">Unidades</InputAdornment>,
                }}
                size='small'
                type='number'
                value={Consumo}
                onChange={(e)=>setConsumo(e.target.value)} 
                label="Consumo Actual"
                variant="outlined" />
            </div>
            <div className='col-12 my-3'>
              <TextField
                id="outlined-multiline-static"
                label="Justifique el cambio de criterio"
                multiline
                fullWidth
                rows={4}
                placeholder='Justifique elcambio de criterio y los stock minimos si corresponde, mientras mas informacion mejor.'
                value={Justificacion}
                onChange={(e)=>setJustificacion(e.target.value.toUpperCase())}
              />
            </div>

          </div>
          <div className='col-12 col-md-6 my-2 text-center'>
            {
              !IsFoundCode?null:
              <a 
                target='_blank'
                href={'http://200.35.158.174:4081/api/getfoto/'+Codigo} >
                <img
                //  src={'http://200.35.158.174:4081/api/getfoto/'+Codigo}
                 src={'https://contador.apidev.info/api/getfoto/'+Codigo}
                //  title={Descripcion}
                 className='img-fluid img-show'/>
              </a>
            }
          </div>
          <div className='col-12 col-md-6 my-2'>
            <button className='btn btn-success w-100' disabled={IsComplete} onClick={()=>SendTataToChangeCriter()}>Solicitar Cambio de Criterio</button>
          </div>
        </div>
      </div>
      {
				 ModalLoadded?<ModalLoaded texto={Texto} />:null
			}
    </div>
  )
}