import React, { useContext, useEffect, useRef, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import { QRCodeCanvas } from 'qrcode.react';
import * as htmlToImage from 'html-to-image';
import AsyncSelect from 'react-select/async';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

/** ICONS */
import { BsQrCode } from "react-icons/bs";
import { URL_SRV_RAIZ } from '../../router/Url';
import LoginContext from '../../context/login_context/LoginContext';
import { ReadQR } from '../../component/ReadQR.component';

export const CrudR = () => {

  const {LoginState} = useContext(LoginContext);

  const [SelMaq, setSelMaq] = useState('');
  const [ObjEquipo, setObjEquipo] = useState(null);
  const [TextObject, setTextObject] = useState("");
  const [Text_qr, setText_qr] = useState("MANTENCION");
  const [padding_left, setpadding_left] = useState(0);

  const qrRef = useRef();
  window.addEventListener('resize',() => {
    var contenedor = document.getElementById('contenedor');
    setpadding_left((contenedor.clientWidth-295)/2)
  })

  const promiseOptionsEquipos = async (inputValue) =>
    new Promise(async(resolve) => {
      if(inputValue.length >= 3){
        let query = await fetch(URL_SRV_RAIZ+'getequipos_fracttal',{
          method: "POST",
          headers: {
            'authorization': "marcuxo",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            TEXT:inputValue.toUpperCase(),
            PLANTA: LoginState.planta.toUpperCase()
          })
        })

        let responsio = await query.json();
        // console.log(responsio)
        resolve(responsio.item)
      }else{
        resolve(null)
      }
    
  });

  const DownloadImgQR = async () => {
    var node = window.document.getElementById('is_the_code');

    htmlToImage.toPng(node)
    .then(function (dataUrl) {
      
      // let la_img = document.getElementById("img_here")
      // la_img.src = dataUrl;
      // document.body.appendChild(la_img);

      let anchor = document.createElement("a")
      anchor.href = dataUrl
      anchor.download = "MY_QR_IS_HERE.png"
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)

      setSelMaq("")
      setText_qr("MANTENCION")
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
    
  }

  const qrcode = (
    <QRCodeCanvas
      id='qrCode'
      value={TextObject}
      renderAs={"svg"}
      size={250}
      bgColor={"#ffffff"}
      level={"M"}
      imageSettings={{
        src:(require("../../assets/LG_ARIZTIA.png")),
        x: undefined,
        y: undefined,
        height: 80,
        width: 80,
        excavate: true,
      }}
    />
  )

  const creaYmodifica_canvas = async () => {
    var canvas = document.getElementById("lienzo");
    var ctx = canvas?.getContext("2d");

    if (ctx) {
      var centerX = canvas.width/2;
      ctx.textAlign="center";
      
      ctx.font="32pt VT323";
      ctx.fillStyle = "white";
      ctx.clearRect(0, 0, 270, 50)
      ctx.fillText(Text_qr,centerX,40);
    }
  }

  useEffect(() => {
    creaYmodifica_canvas()
  }, [Text_qr])

  useEffect(() => {
    if(SelMaq===""){
      setTextObject("")
      setObjEquipo(null)
      setText_qr("MANTENCION")
    }else{
      setTextObject(`{"CODIGO":"${SelMaq.value}","PLANTA":"${LoginState.planta.toUpperCase()}"}`)
      setObjEquipo({"DESCRIPCION":SelMaq.label,"CODIGO":SelMaq.value,"PLANTA":LoginState.planta.toUpperCase()})
      setText_qr(SelMaq?.value||"")

    }
  }, [SelMaq])

  return (<>
    <HeaderMenu Pag_Sel={'crudr'} />
    <div className='container'>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <div className='row'>
            <div className='col-12 col-md-2'></div>
            <div className='col-12 col-md-8' id='contenedor' style={{paddingLeft: `${padding_left}px`}}>
                <div className='frame_2' id='is_the_code' style={{width: "295px"}}>
                  <div className='frame_1' ref={qrRef}>{qrcode}</div>
                  <div className='label_qr pt-2'>
                    {/* {Text_qr} */}
                    <canvas width="270px" height="50px" id="lienzo" style={{backgroundColor: "black"}}>Su navegador no soporta canvas :( </canvas>
                  </div>
                </div>
            </div>
            <div className='col-12 col-md-2'></div>
          </div>
        </div>
        
        <div className='col-12 col-md-6'>
          <div className='row'>

            <div className='col-12 mt-3'>
              <label>Busque y Seleccione Maquina o Equipo</label>
              <AsyncSelect
                isClearable
                isSearchable
                placeholder={'Buscar Equipo'}
                value={SelMaq}
                onChange={(e)=>setSelMaq(e?e:"")}
                loadOptions={promiseOptionsEquipos}
              />
            </div>

            <div className='col-12'>
              <label>Texto Etiqueta</label>
              <input
                placeholder='Texto de la etiqueta del QR'
                className='form-control form-control-sm'
                readOnly
                type='text'
                value={Text_qr}
              />
            </div>

            <div className='col-12'>
              <label>Planta</label>
              <input
                placeholder='Planta a la que pertenece el equipo'
                className='form-control form-control-sm'
                readOnly
                type='text'
                value={LoginState.planta.toUpperCase()}
              />
            </div>

            <div className='col-12'>
              <label>Descripcion</label>
              <input
                placeholder='Texto del codigo QR'
                className='form-control form-control-sm'
                readOnly
                type='text'
                value={TextObject}
              />
            </div>

            <div className='col-12'>
                <label className='mt-2'></label><br/>
              <button
                className='btn btn-sm btn-success w-100 mt-2'
                disabled={TextObject===""?true:false}
                // onClick={(e)=>downloadQrCode(e)}
                // onClick={(e)=>saveDataInSRV(e)}
                onClick={(e)=>DownloadImgQR(e)}
              >Descargar <BsQrCode/></button>
            </div>
          </div>
        </div>
        <ReadQR />
        
        <img id='img_here'>

        </img>
      </div>
    </div>
  </>
  )
}
