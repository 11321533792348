import React, { useContext, useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MakeMeAFile from '../../api/MakeMeAFile.api';
import LoginContext from '../../context/login_context/LoginContext';
import { FormControl, MenuItem, Select } from '@mui/material';

export default function ModalMakeMeAFileByDate({VisibleModal,setVisibleModal}) {
  const {LoginState} = useContext(LoginContext);
  // console.log(LoginState)
  const [Desde, setDesde] = useState('');
  const [Hasta, setHasta] = useState('');
  const [IsOkRange, setIsOkRange] = useState(true);
  const [Turno, setTurno] = useState('0');
  // console.log('one',VisibleModal)

  const SendToMakeAReport = async () => {
    if(Desde === "" || Hasta === ""){
      window.alert("Debe seleccionar un rango de fechas para generar el reporte.")
    }else{
      let respMF = await MakeMeAFile({PLANTA:LoginState.planta,DESDE:Desde,HASTA:Hasta,TURNO: Turno})
      if(respMF){
        setDesde('');
        setHasta('');
        setTurno('0');
        setVisibleModal(false);
      }
    }
  }
  
  useEffect(() => {
    if(Desde === "" || Hasta === "")setIsOkRange(true)
    else setIsOkRange(false)
  }, [Desde, Hasta, Turno])
  
  if(VisibleModal){
    return (
      <div className="container">
        <div className='row' style={{height:'90vh'}}>
          <div className="col text-center modal_mf">
            <div className='row modal_cont text-left p-3'>
              <div className='col-12'>
                <span className='lead'>Seleccione el rango de fecha para generar un reporte de solicitudes</span>
              </div>
              <div className='col-12 col-md-4 mt-3'>
                <label className=''>Desde</label>
                <input type='date' onChange={(e)=>setDesde(e.target.value)} className='form-control form-control-sm' max={new Date().toISOString().split('T')[0]} />
              </div>
              <div className='col-12 col-md-4 mt-3'>
                <label className=''>Hasta</label>
                <input type='date' onChange={(e)=>setHasta(e.target.value)} className='form-control form-control-sm' max={new Date().toISOString().split('T')[0]} />
              </div>
              <div className='col-12 col-md-4 mt-3'>
                <label className=''>Turno</label>
                <select
                  className="form-control form-control-sm"
                  value={Turno}
                  onChange={(e)=>setTurno(e.target.value)}
                >
                  <option value="0" defaultChecked>Seleccione turno</option>
                  <option value="TARDE">Tarde</option>
                  <option value="MALÑANA">Mañana</option>
                  <option value="NOCHE">Noche</option>
                </select>
              </div>
              <div className='col text-right mt-3'>
                <button className='btn btn-warning btn-sm' onClick={() => setVisibleModal(false)}>Cerrar</button>
                <span className='mx-3'></span>
                <button disabled={IsOkRange} className='btn btn-info btn-sm' onClick={() =>SendToMakeAReport()}>Generar Reporte</button>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      
    )
  } else {return null}
}
