import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const ConfirmChange = ({ID}) => {
  // console.log(ID);
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_ARIZTIA+'sendconfirmcriterion',{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ID
      })
    })
    let responsito = await query.json();
    console.log(responsito);
    if(responsito.success){
      resolve(responsito.body)
    }else{
      resolve(null)
    }
  })
}
