import { URL_PUT_ADDNOTE, URL_STATUS_TO_REVCN } from "../router/Url";


export const Put_AddNote = async ({wo_folio, comment}) => {
  console.log(wo_folio, comment)

  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_PUT_ADDNOTE,{
      method: 'PUT',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        wo_folio:wo_folio,
        comment: comment
      })
    })
    let responsito = await query.json();
    console.log(responsito);
    if(responsito.success){
      alert('El Comentario se guardo con exito')
      resolve(responsito.success)
    }else{
      resolve(responsito.success)
    }
  })
 
}
