import { URL_ADD_REGISTRO, URL_UPDATE_SUB_TASKS } from "../router/Url";


export const AddRegistro = async (data) => {
  console.log({
    taskID:data.taskID,
    fechas:{start: data.fechas.start, end: data.fechas.end}
  })

  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_ADD_REGISTRO,{
      method: 'PUT',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        taskID:data.taskID,
        fechas:{start: data.fechas.start, end: data.fechas.end}
      })
    })
    let responsito = await query.json();
    console.log(responsito);
    if(responsito.success){
      alert("El registro fue creado con exito")
      resolve(responsito.success)
    }else{
      console.log('ERROR REGISTRO');
      if(responsito.message === "500")resolve(alert('Error de servidor, Intentelo mas tarde'))
      if(responsito.message === "404")resolve(alert('Error de servidor, Intentelo mas tarde'))
    }
  })
 
}
