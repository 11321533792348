import React, { useEffect } from 'react'

export const ModalLoaded = ({texto}) => {

  return (
    <div className='loader-01 justify-content-center'>
      <div className='container-load-01 text-center'>
        <div className='inner-load-01'>
          <span className=''>{texto}</span>
          <div className="ml-3 spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  )
}
