import { URL_ADD_RECURSOH } from "../router/Url";

export const AddResouceH = async (data) => {
  console.log(data)

  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_ADD_RECURSOH,{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        wo_folio:data.wo_folio,
        rut: data.rut,
        minutos: data.minutos,
        taskID: data.taskID
      })
    })
    let responsito = await query.json();
    console.log(responsito);
    if(responsito.success){
      alert('El Recurso Humano Fue agregado con exito')
      resolve(responsito.success)
    }else{
      resolve(responsito.success)
    }
  })
 
}
