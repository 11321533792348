/** Solicitud de Trabajo */
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { HeaderMenu } from '../../component/HeaderMenu'
import alertchek from "../../assets/fa-alert-chek.svg";
import logo from "../../assets/logo.svg";
import paste from "../../assets/fa-paste.svg";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Rating, Tab, Tabs, Typography, styled } from '@mui/material';
import { FaListCheck } from "react-icons/fa6";
import ConstructionIcon from '@mui/icons-material/Construction';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const St = () => {
  const [VisibleModal, setVisibleModal] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <HeaderMenu Pag_Sel={'st'} VisibleModal={VisibleModal} setVisibleModal={setVisibleModal} />
      <div >St.page</div>
      <img src={paste} height={50}/>

    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab icon={<FaListCheck/>} label="SUBTAREA" {...a11yProps(0)} />
          <Tab icon={<ConstructionIcon/>} label="RECURSOS" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        Item One
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
    </Box>
    
    </>
  )
}

export default St