import { Route, Routes } from 'react-router-dom';
import './App.css';
import LoginState from './context/login_context/LoginState';
import { Login } from './pages/login/Login.page';
import { ProtectedRouter } from './component/Protected/ProtectedRouter';
import Home from './pages/session/Home.page';
import ModalMakeMeAFileByDate from './pages/modal/ModalMakeMeAFileByDate.comp';
import Ot from './pages/session/Ot.page';
import St from './pages/session/St.page';
import { SolicitudesDeTrabajo } from './pages/session/SolicitudesDeTrabajo.page';
import { OtPdf } from './pages/session/OtPdf.page';
import { TaskList } from './pages/session/TaskList.page';
import { CambioCriticidad } from './pages/session/CambioCriticidad.page';
import { CrudCriticidad } from './pages/session/CrudCriticidad.page';
import { EntregaTurno } from './pages/session/EntregaTurno.page';
import { CrudR } from './pages/session/CrudR.page';

// let now = Math.floor((new Date).getTime() / 1000)
// console.log(now, now-(60*15));

function App() {
  
  return (
    <LoginState>
      <Routes>
        <Route path='/' element={<Login />} />
        {/* <Route path='/recuperar_cuenta' element={<RecoveryPass />}/>
        <Route path='/recuperar_cuenta' element={<RecoveryPass />}/> */}
        
        <Route path='/ot' element={
          <ProtectedRouter>
            <Ot />
          </ProtectedRouter>
        } />

        <Route path='/tasks' element={
          <ProtectedRouter>
            <TaskList />
          </ProtectedRouter>
        } />

        <Route path='/cambiocritico' element={
          <ProtectedRouter>
            <CambioCriticidad />
          </ProtectedRouter>
        } />

        <Route path='/crudr' element={
          <ProtectedRouter>
            <CrudR />
          </ProtectedRouter>
        } />

        <Route path='/crudticidades' element={
          <ProtectedRouter>
            <CrudCriticidad />
          </ProtectedRouter>
        } />
        
        <Route path='/home' element={
          <ProtectedRouter>
            <Home />
          </ProtectedRouter>
        } />

        <Route path='/solicitudes' element={
          <ProtectedRouter>
            <SolicitudesDeTrabajo />
          </ProtectedRouter>
        } />

        <Route path='/st' element={
          <ProtectedRouter>
            <St />
          </ProtectedRouter>
        } />

        <Route path='/pdf' element={
          <ProtectedRouter>
            <OtPdf />
          </ProtectedRouter>
        } />

        <Route path='/entregaturno' element={
          <ProtectedRouter>
            <EntregaTurno />
          </ProtectedRouter>
        } />

        <Route path='*' element={<Login />} />
      </Routes>
    </LoginState>
  );
}

export default App;
