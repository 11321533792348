import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import AsyncSelect from 'react-select/async';
import { URL_SRV_RAIZ } from '../../router/Url';
import LoginContext from '../../context/login_context/LoginContext';
import { Button, FormControl, FormControlLabel, FormGroup, MenuItem, Select, Switch, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

// ICONS
import AttachmentIcon from '@mui/icons-material/Attachment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { CreaSolicitud } from '../../api/CreaSolicitud.api';
import { UploadFile } from '../../api/UploadFile.api';
import { ModalLoaded } from '../../component/ModalLoaded.component';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  padding:0,
  margin:0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const SolicitudesDeTrabajo = () => {

  const {LoginState} = useContext(LoginContext);
  // console.log(LoginState);
  const [SelMaq, setSelMaq] = useState('');
  const [DScrtec, setDScrtec] = useState('');
  const [Observacion, setObservacion] = useState('');
  const [FileData, setFileData] = useState({file:{}, filename:''});
  const [IsUrgente, setIsUrgente] = useState(false);
  const [tipo, setTipo] = useState('');

  const [IsComplete, setIsComplete] = useState(true);
  const [ModalLoadded, setModalLoadded] = useState(false);

  const promiseOptionsEquipos = async (inputValue) =>
    new Promise(async(resolve) => {
      if(inputValue.length >= 3){
        let query = await fetch(URL_SRV_RAIZ+'getequipos_fracttal',{
          method: "POST",
          headers: {
            'authorization': "marcuxo",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            TEXT:inputValue.toUpperCase(),
            PLANTA: LoginState.planta.toUpperCase()
          })
        })

        let responsio = await query.json();
        // console.log(responsio)
        resolve(responsio.item)
      }else{
        resolve(null)
      }
    
  });
  
  // console.log(new Date().getTime().toString());
  const CrearSolicitudDeTrabajo = async () => {
    await setModalLoadded(true)
    // console.log(SelMaq?.value, DScrtec, LoginState.rut, Observacion);
    let u_file = ''
    if(FileData.filename){
      u_file = await UploadFile({taskID: new Date().getTime().toString(), file:FileData.file})
    }
    // console.log(u_file);
    // console.log('debe salir despues de cargar la foto');
    
    await CreaSolicitud({
      type_1: tipo,
      code: SelMaq?.value, // AGP LIGADO A LA TAREA
      description:"OBSERVACIONES: "+ DScrtec, // OBSERVACIONES + NOMBREE DE LA TAREA
      comment: Observacion+"\n"+u_file, // NEGATICO + OBSERVACION OBLIGATORIA
      name: LoginState.user, // NOMBRE USUARIO LOGUEADO NO RUT
      reference: '', // NUMERO DE OT
      is_urgent: IsUrgente // URGENCIA DE SOLICITUD
    })

    setSelMaq('');
    setTipo('')
    setDScrtec('');
    setObservacion('');
    setFileData({file:{}, filename:''});
    setIsUrgente(false);
    setTimeout(() => {
      setModalLoadded(false);
    }, 3000);
  }

  useEffect(() => {
    if(SelMaq === '' || DScrtec === '' || Observacion === ''){
      setIsComplete(true)
      // console.log(true, SelMaq, DScrtec, Observacion, FileData.filename);
    }else{
      setIsComplete(false)
      // console.log(false, SelMaq, DScrtec, Observacion, FileData.filename);
    }
    return () => {
      
    }
  }, [SelMaq, DScrtec, Observacion])
  
  return (
    <>
      <HeaderMenu Pag_Sel={'solicitudes'} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-12'>
            <span className='lead'>Solicitud de Trabajo</span>
          </div>
          <div className='col-12 col-md-6 '>
            <div className='col-12 card_form'>
              <label>Busque y Seleccione Maquina o Equipo</label>
              <AsyncSelect isClearable isSearchable  placeholder={'Buscar Equipo'} value={SelMaq} onChange={(e)=>setSelMaq(e?e:'')} loadOptions={promiseOptionsEquipos} />
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='col-12 card_form'>
              <label>Descripcion Tecnica</label>
              <TextField
                style={{backgroundColor: '#fff'}}
                fullWidth
                size='small'
                id="outlined-textarea"
                // label="Descripcion Tecnica"
                placeholder="Ingrese una breve descripcion tecnica..."
                multiline={true}
                value={DScrtec}
                onChange={(e)=>setDScrtec(e.target.value)}
              />
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='col-12 card_form'>
              <label>Observacion</label>
              <TextField
                style={{backgroundColor: '#fff'}}
                fullWidth
                size='small'
                id="outlined-textarea"
                // label="Descripcion Tecnica"
                placeholder="Ingrese una breve observacion..."
                multiline={true}
                value={Observacion}
                onChange={(e)=>setObservacion(e.target.value)}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 autocenter'>
            <div className='col-12 card_form'>
              <label>Tipo de Trabajo</label>
              <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label" size='small'>Selecione Maquina o Equipo</InputLabel> */}
              <Select
                style={{backgroundColor: '#fff'}}
                size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tipo}
                onChange={(e)=>setTipo(e.target.value)}
              >
                <MenuItem value={"ELECTRICO"}>Electrico</MenuItem>
                <MenuItem value={"GASFITER"}>Gasfiter</MenuItem>
                <MenuItem value={"MECANICO"}>Mecanico</MenuItem>
                <MenuItem value={"REDES"}>Redes</MenuItem>
                <MenuItem value={"SOLDADOR"}>Soldadura</MenuItem>
                <MenuItem value={"REFRIGERACION"}>Refrigeracion</MenuItem>
              </Select>
              </FormControl>
            </div>
          </div>
          <div className='col-12 col-md-6 text-break text-center'>
            <div className='col-12 card_form'>
              <p>Seleccione Imagen Adjunta</p>
              <Button  component="label" color='secondary' variant="contained">
                {
                  FileData.filename!==''?FileData.filename:<AttachmentIcon />
                }
                <VisuallyHiddenInput
                  accept='image/jpeg'
                  type="file"
                  onChange={(e)=>{
                    setFileData({...FileData, ['filename']:e.target.files[0].name,['file']:e.target.files[0]})
                  }}
                />
              </Button>
              <p style={{color:'#CACACA', margin:0, fontSize: 'small'}}>Max. 2MB</p>
            </div>
          </div>
          <div className='col-12 col-md-6 pt-5 text-break text-center'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    value={IsUrgente}
                    color='error'
                    onChange={(e)=>setIsUrgente(e.target.checked)}
                  />}
                label="Es Urgente" />
            </FormGroup>
          </div>
          <div className='col-12 text-center my-5'>
            <button
              disabled={IsComplete}
              className='btn btn-sm btn-success'
              onClick={()=>CrearSolicitudDeTrabajo()}
            ><ReceiptLongIcon/>  Crear solicitud</button>
          </div>
        </div>
      </div>
      {/* <button onClick={()=>console.log(IsUrgente)}>boton!!</button> */}
        {
          ModalLoadded?<ModalLoaded texto={'En estos momentos se esta creando la solicitud, por favor espere!!'} />:null
        }
    </>
  )
}