import { useContext } from 'react';
import { Button, Drawer, Fab, TextField } from '@mui/material'
import React, { useState } from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import LoginContext from '../context/login_context/LoginContext';
import { AddRegistro } from '../api/AddRegistro.api';
import { MobileTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';

/** ICONS */
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SaveIcon from '@mui/icons-material/Save';

dayjs().locale('es-CL').format()
export const DrawerRegistroSelDate = ({task, ModalLoadded, setModalLoadded, setTexto, Reload_, setReload_}) => {
  // console.log(task);
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);

  let hoy = dayjs()
  // let a = hoy.setMinutes(hoy.getMinutes-10)
  const [value_a, setValue_a] = React.useState(dayjs().subtract(10, "minute"));
  const [value_b, setValue_b] = React.useState(dayjs());
  const [taskID, setTaskID] = React.useState(task?.id_work_orders_tasks);
  const [dataChanged, setDataChanged] = React.useState([]);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const HandleAddRegistro = async () => {
    await setTexto('Agregando Registro ala SubTarea.')
    await setModalLoadded(true)
    let add_registro = await AddRegistro({taskID:taskID, fechas:{start: new Date(value_a).getTime(), end: new Date(value_b).getTime()}})
    // console.log({taskID:taskID, fechas:{start: value_a.unix(), end: value_b.unix()}})
    setReload_(Reload_ + 1)
    setDataChanged(add_registro)
    setState({ ...state, ['right']: false })
    await setModalLoadded(false)
  }

  return (
    <div className='text-right pr-3 pb-3'>
      <Fab color="primary" aria-label="add" onClick={()=>setState({ ...state, ['right']: true })}>
        <AddIcon />
      </Fab>

      <Drawer
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
        // id={task.id_task}
        // key={task.id_task}
      >
        <div className='container-crudmenu_2' style={{overflowY: 'scroll', height: '100vh'}}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 header-crud mb-4'>
                <div className='row py-2'>
                  <div className='col-2 text-center puntero' onClick={()=>setState({ ...state, ['right']: false })}>
                    <ArrowBackIosNewIcon color='info' />
                  </div>
                  <div className='col-10'>
                    <span>Tarea: {task.description}</span>
                  </div>
                </div>
              </div>
              <div className='col-12 my-2'>
                <TextField fullWidth disabled value={task.description} id="outlined-basicTarea" label="Tarea" variant="outlined" />
              </div>
              <div className='col-12 my-2'>
                <TextField fullWidth disabled value={task.user_assigned} id="outlined-basicUsuario" label="Usuario" variant="outlined" />
              </div>
              <div className='col-12'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                    <DateTimePicker
                      value={value_a}
                      onChange={(newValue) => setValue_a(newValue)}
                      label="Fecha Inicial"
                      ampm={false}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                    <DateTimePicker
                      value={value_b}
                      onChange={(newValue) => setValue_b(newValue)}
                      label="Fecha Final"
                      ampm={false}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className='col-12 py-2 fixed-footer'>
                <div className='row'>
                  <div className='col-12'>
                    <Button
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={()=>HandleAddRegistro()}
                    >Guardar y Finalizar</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}
