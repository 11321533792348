import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url'

export const AgregarEvento = ({OT, MAQ, RSP, DESC, RUT, USER, PLANTA, TIMESTAMP, TISO}) => {
  // console.log(OT, MAQ, RSP, DESC, RUT, USER, PLANTA, TIMESTAMP, TISO)
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_ARIZTIA+"add_new_event",{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        OT, MAQ, RSP, DESC, RUT, USER, PLANTA, TIMESTAMP, TISO
      })
    })
    let responsito = await query.json();
    console.log(responsito);
    if(responsito.success){
      alert("El registro fue creado con exito")
      resolve(responsito.success)
    }else{
      console.log('ERROR REGISTRO');
      if(responsito.message === "500")resolve(alert('Error de servidor, Intentelo mas tarde'))
      if(responsito.message === "404")resolve(alert('Error de servidor, Intentelo mas tarde'))
    }
  })
}
