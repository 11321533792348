import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import { InputAdornment, TextField } from '@mui/material'
import AsyncSelect from 'react-select/async';
import { URL_SRV_RAIZ } from '../../router/Url';
import LoginContext from '../../context/login_context/LoginContext';

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { GetUserFracttal } from '../../api/GetUserFracttal.api';
import { AgregarEvento } from '../../api/LibroTurno/AgregarEvento.api';
const animatedComponents = makeAnimated();

export const EntregaTurno = () => {

  const {LoginState} = useContext(LoginContext);
  // console.log(LoginState)
  
  const [Options, setOptions] = useState('');//select responsable
  const [CardOnOff, setCardOnOff] = useState(false);//muestra u oculta card formulario(Boolean=true/false)
  // valores del formulario
  const [OT_,         setOT_] = useState('');
  const [SelMaq,      setSelMaq] = useState('');
  const [DESC__,      setDESC__] = useState('');
  const [RSPONSBL__,  setRSPONSBL__] = useState([]);

  const promiseOptionsEquipos = async (inputValue) =>
    new Promise(async(resolve) => {
      if(inputValue.length >= 3){
        let query = await fetch(URL_SRV_RAIZ+'getequipos_fracttal',{
          method: "POST",
          headers: {
            'authorization': "marcuxo",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            TEXT:inputValue.toUpperCase(),
            PLANTA: LoginState.planta.toUpperCase()
          })
        })

        let responsio = await query.json();
        // console.log(responsio)
        resolve(responsio.item)
      }else{
        resolve(null)
      }
    
  });

  const ObtenerUsuariosFracttal = async () => {
    let usersList = await GetUserFracttal(LoginState.planta);
    setOptions(usersList)
  }

  const formatOt = async (e) => {
    let a = e.split('OT').length>1?e.split('OT')[1]:""
    await setOT_("OT"+a)
  }

  const AgregarNuevoEvento = async () => {
    let f = new Date()
    let ts = f.getTime()
    let tIso = f.toISOString()
    await AgregarEvento({ OT:OT_, MAQ:SelMaq, RSP:RSPONSBL__, DESC:DESC__, RUT:LoginState.rut, USER: LoginState.user, PLANTA: LoginState.planta, TIMESTAMP: ts, TISO: tIso});
  }

  useEffect(() => {
    ObtenerUsuariosFracttal()
    return () => {
      
    }
  }, [])
  

  return (
    <>
      <HeaderMenu Pag_Sel={'entregaturno'} />
      <div className='container-fluid mb-5'>
        <div className='row p-3'>
          {/* AGREGA UN NUEVO EVENTO */}
          <div className='col-12 text-center'>
            <button onClick={()=>setCardOnOff(true)} className={CardOnOff?'card-new-event-close':'btn btn-primary btn-sm mb-2 w-50'}>AGREGAR PENDIENTE</button>
          </div>
          <div className={CardOnOff?'col-12 my-3 py-2 card-new-event-open':'col-12 my-3 py-2 card-new-event-close'}>
            <div className='row'>
              <div className='col-12 col-md-8'>
                <div className='row'>
                  <div className='col-12 col-md-4 my-2'>
                      <div className="form-group m-0 p-0">
                        <input value={OT_} type="text" onChange={(e)=>formatOt(e.target.value)} className="form-control" placeholder="OT"/>
                      </div>
                  </div>
                  <div className='col-12 col-md-8 my-2'>
                    <AsyncSelect
                      isClearable
                      isSearchable
                      placeholder={'MAQUINA O EQUIPO'}
                      value={SelMaq} onChange={(e)=>setSelMaq(e?e:'')} loadOptions={promiseOptionsEquipos} />
                  </div>
                  <div className='col-12 col-md-12 my-2'>
                    <Select
                      value={RSPONSBL__}
                      onChange={(e)=>setRSPONSBL__(e)}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      options={Options}
                      isMulti
                      placeholder={'RESPONSABLE(S) DEL PENDIENTE'}
                    />
                  </div>
                  <div className='col-6 py-2'>turno</div>
                  <div className='col-6 py-2'>
                    <select className="form-control" id="exampleFormControlSelect123" placeholder='SELECCIONE TURNO'>
                      <option selected value={null}>SELECCIONE TURNO</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-4 my-2'>
                <textarea className="form-control" value={DESC__} onChange={(e)=>setDESC__(e.target.value.toUpperCase())} rows="4" placeholder='DESCRIPCION'></textarea>
              </div>
              <div className='col-6 py-2'>
                <button
                  className='btn btn-sm btn-success'
                  onClick={()=>AgregarNuevoEvento()}
                >AGREGAR</button>
                <button
                  className='btn btn-sm btn-danger ml-3'
                  onClick={()=>{
                    setCardOnOff(false)
                    setOT_('')
                    setSelMaq('')
                    setDESC__('')
                    setRSPONSBL__([])
                  }}
                >CANCELAR</button>
              </div>
             
            </div>
          </div>

          {/* vista para web */}
          <div className='col-12 p-0'>
          <table className="table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <th className=''><small>OT</small></th>
                <th className=''><small>MAQUINA O EQUIPO</small></th>
                <th className=''><small>DESCRIPCION</small></th>
                <th className=''><small>REGISTRO</small></th>
                <th className=''><small>SOLICITANTE</small></th>
                <th className=''><small>RESPONSABLE</small></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr>

              {/* BORRAR DATOS DE EJEMPLO */}
              <tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr><tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr><tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr><tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr><tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr><tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr><tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr><tr>
                <th><small>OT262530</small></th>
                <th><small>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</small></th>
                <th><small>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</small></th>
                <th><small>13-06-2023</small></th>
                <th><small>PEDRO HUERTA</small></th>
                <th><small>PEDRO HUERTA</small></th>
              </tr>
              {/* BORRAR DATOS DE EJEMPLO */}
              
            </tbody>
          </table>
          </div>

          {/* vista para celulares */}
          {/* maqueta de cards */}
          <div className='col-12 col-md-6 p-0 m-0'>
            <div className='row m-1 py-3 card-container'>
              <div className='col-3 pt-2'>
                <span className='float-title-card'><small>OT</small></span>
                <span>OT262530</span>
              </div>
              <div className='col-9 pt-2'>
                <span className='float-title-card'><small>Maquina o Equipo</small></span>
                <span>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</span>
              </div>
              <div className='col-12 py-2'>
                <span><b>Descripcion</b></span><br/>
                <span>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</span>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Registro</small></span>
                <small><b>13-06-2023</b></small>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Solicitante</small></span>
                <small>PEDRO HUERTA</small>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Responsable</small></span>
                <small>PEDRO HUERTA</small>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 p-0 m-0'>
            <div className='row m-1 py-3 card-container'>
              <div className='col-3 pt-2'>
                <span className='float-title-card'><small>OT</small></span>
                <span>OT262530</span>
              </div>
              <div className='col-9 pt-2'>
                <span className='float-title-card'><small>Maquina o Equipo</small></span>
                <span>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</span>
              </div>
              <div className='col-12 py-2'>
                <span><b>Descripcion</b></span><br/>
                <span>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</span>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Registro</small></span>
                <small><b>13-06-2023</b></small>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Solicitante</small></span>
                <small>PEDRO HUERTA</small>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Responsable</small></span>
                <small>PEDRO HUERTA</small>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 p-0 m-0'>
            <div className='row m-1 py-3 card-container'>
              <div className='col-3 pt-2'>
                <span className='float-title-card'><small>OT</small></span>
                <span>SIN OT</span>
              </div>
              <div className='col-9 pt-2'>
                <span className='float-title-card'><small>Maquina o Equipo</small></span>
                <span>BOMBA DE ESTANQUE DE PREPARACION DE SALMUERA N°3</span>
              </div>
              <div className='col-12 py-2'>
                <span><b>Descripcion</b></span><br/>
                <span>CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W CAMBIAR PROYECTOR LED INSPECCION FINAL CINTA COLGADO 150W</span>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Registro</small></span>
                <small><b>13-06-2023</b></small>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Solicitante</small></span>
                <small>PEDRO HUERTA</small>
              </div>
              <div className='col-4 pt-2'>
                <span className='float-title-card'><small>Responsable</small></span>
                <small>PEDRO HUERTA</small>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  )
}
