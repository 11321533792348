import React, { useContext, useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import { Button, FormControl, FormControlLabel, Input, MenuItem, Radio, RadioGroup, Select, Switch, TextField, Tooltip, Zoom } from '@mui/material'
import LoginContext from '../../context/login_context/LoginContext';
import AsyncSelect from 'react-select/async';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import { URL_SRV_RAIZ } from '../../router/Url';
import ModalMakeMeAFileByDate from '../modal/ModalMakeMeAFileByDate.comp';
import { ModalLoaded } from '../../component/ModalLoaded.component';

function Home() {
  const {LoginState} = useContext(LoginContext);
  // console.log(LoginState)
  const [SelMaq, setSelMaq] = useState('');
  const [DScrtec, setDScrtec] = useState('');
  const [Stado, setStado] = useState('Finalizado');
  const [SelResp, setSelResp] = useState('');
  const [checked, setChecked] = useState(true);
  const [minutos, setMinutos] = useState('');
  const [tipo, setTipo] = useState('');
  const [Turno, setTurno] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [Programado, setProgramado] = useState('Programada');
  const [ProgramadoFecha, setProgramadoFecha] = useState('');
  const [Duration, setDuration] = useState('');

  const [SelANterior, setSelANterior] = useState('');
  const [IsFullOk, setIsFullOk] = useState(true);
  const [VisibleModal, setVisibleModal] = useState(false)
  const [CantRecurso, setCantRecurso] = useState(0)
  const [UserListFracttal, setUserListFracttal] = useState([])
  const [UserListFracttalList, setUserListFracttalList] = useState([])
  const [InicioJob, setInicioJob] = useState('')
  const [FinJob, setFinJob] = useState('')
  const [ArRecurso, setArRecurso] = useState([{"rut": '', "minutos": '', "id":0}]);
  const [ModalLoadded, setModalLoadded] = useState(false);

  //1.- envia los dato al endPoint de pedro y al response pasa ala siguiente funcion que envia los datos a mi api
  const SendData = async () => {
    
    let fecha = new Date()
    let a = fecha.toLocaleString().split(' ')[1]
    let b = fecha.toISOString().split('T')[0]
    let c = b+'T'+a+'-03'//'.000Z'

    let SOTCpedro = {
    "code" : SelMaq?.value,
    "description" : DScrtec.toUpperCase(),
    "status" : Stado.toUpperCase(),
    "responsible" : SelResp.includes('_')?SelResp.split('_')[0]:SelResp,
    "type" : tipo.toUpperCase(),
    "user_code" : LoginState.rut,
    "comment" : observaciones.toUpperCase(),
    "name" : LoginState.user.toUpperCase(),
    "recurso" : ArRecurso,
    "programado" : ProgramadoFecha?ProgramadoFecha.concat('T03:00:00-03'):new Date().toISOString(),
    "type_pending": Programado,
    "StartEndJob": {"start": new Date(InicioJob).getTime(), "end": new Date(FinJob).getTime()},
    "duration": Duration * 60,
    "have_detencion": checked
    }
    
    // console.log(SOTCpedro)

    let SOTCowner = {
      "OT": "del response",
      "CODIGO_MAQ": SelMaq.value,
      "MAQUINA": SelMaq.label,
      "DESCRIPCION": DScrtec.toUpperCase(),
      "ESTADO": Stado.toUpperCase(),
      "FECHA_REGISTRO": c,
      "REGISTRADO_POR": LoginState.user,
      "RESPONSABLE_PENDIENTE": SelResp,
      "TIPO_SOLICITUD":tipo,
      "DETENCION": checked?'SI':'NO',
      "TIEMPO_DETENCION": minutos,
      "TURNO": Turno,
      "COMENTARIO" : observaciones.toUpperCase(),
      "PLANTA":LoginState.planta
    }
    // console.log(SOTCowner)
    let confirmacion = window.confirm('Se creará una OT con los datos ingresados.\n Está seguro de continuar con los datos ingresados.')
    if(confirmacion){
      await setModalLoadded(true)

      new Promise(async(resolve) => {
        let query = await fetch('https://ariztock.apidev.info/api/fracttal/correctivo',{//chage endpoint to get by Pedro Gutierrez
          method: "POST",
          headers: {
            'authorization': "marcuxo",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(
            SOTCpedro
          )
        })

        let responsio = await query.json();
        console.log(responsio)
        //await number of OT to save for generate a repor
        if(responsio.success){
          resolve(SentToSaveToReport(SOTCowner,responsio.data)) 
        }else{
          alert('Ocurrió un error vuelva a intentarlo por favor!!.')
        }
        
      });

      setModalLoadded(false)
    }else{
      return
    }
  }

  //2.-envia los datos a mi api para fuardarlos en una base de datos y generar reporte
  const SentToSaveToReport = async (owner,response) => {
    let OT = response.wo_folio
    let owner_ = owner
    owner_["OT"] = OT
    // console.log('===========>',owner)
    new Promise(async(resolve) => {
      let query = await fetch(URL_SRV_RAIZ+'adddata_fracttal',{//chage endpoint to save data
        method: "POST",
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(
          {OWNER:owner,FRACTTAL:response}
        )
      })

      let responsio = await query.json();
      // console.log(responsio)
      //await number of OT to save for generate a repor
      if(responsio.success){
        if(OT === "sin folio") alert(`Se creo una tarea pendiente a cargo de planificación.`)
        else alert(`Se creo una OT con el código ${OT} a cargo de ${SelResp}.`)
        resolve(window.location.reload())
      }else{
        alert('Ocurrió un error vuelva a intentarlo por favor!!.')
        resolve()
      }
      
    });

  }
  
  const promiseOptionsNombre = async (inputValue) =>
    new Promise(async(resolve) => {
    if(inputValue.length >= 3){
      // console.log(inputValue)
      let finder = UserListFracttal.filter(nsr => nsr.label.includes(inputValue.toUpperCase()))
      resolve(setUserListFracttalList(finder))
    }
  });

  const promiseOptionsEquipos = async (inputValue) =>
  new Promise(async(resolve) => {
    if(inputValue.length >= 3){
      let query = await fetch(URL_SRV_RAIZ+'getequipos_fracttal',{
        method: "POST",
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          TEXT:inputValue.toUpperCase(),
          PLANTA: LoginState.planta.toUpperCase()
        })
      })

      let responsio = await query.json();
      // console.log(responsio)
      resolve(responsio.item)
    }else{
      resolve(null)
    }
    
  });

  // cambia y reordena los valores de rut del array de RECURSOS
  const ChangeValueUsrFttl = async (e,index) => {
    // console.log('e',e.target.value,index, ArRecurso[index])
    let sf = ArRecurso.filter(fi => fi.id === index)
    sf[0]["rut"] = e.target.value
    let nf = ArRecurso.filter(fi => fi.id !== index)
    let con = sf.concat(nf)
    con.sort((a,b)=>{
      if(a.id>b.id){
        return 1;
      }
      if(a.id<b.id){
        return -1;
      }
      return 0;
    })
    // console.log(con)
    setArRecurso(con)
  }

  //cambia y reordena los valores de minutos del array de RECURSOS
  const ChangeValueminits = async (e,index) => {
    // console.log('e',e.target.value,index, ArRecurso[index])
    let sf = ArRecurso.filter(fi => fi.id === index)
    sf[0]["minutos"] = e.target.value
    let nf = ArRecurso.filter(fi => fi.id !== index)
    let con = sf.concat(nf)
    con.sort((a,b)=>{
      if(a.id>b.id){
        return 1;
      }
      if(a.id<b.id){
        return -1;
      }
      return 0;
    })
    // console.log(con)
    setArRecurso(con)
  }

  //elimina un iten seleccionado del array de RECURSOS
  const DeleteItemFromRECURSOS = async (index) => {
    let nf = ArRecurso.filter(fi => fi.id !== index)
    // con.sort((a,b)=>{
    //   if(a.id>b.id){
    //     return 1;
    //   }
    //   if(a.id<b.id){
    //     return -1;
    //   }
    //   return 0;
    // })
    // console.log(con)
    setArRecurso(nf)
  }

  useEffect(() => {
    if(!checked){
      setMinutos('')
    }
  }, [checked])
  
  useEffect(() => {
    if(Stado === 'Finalizado'){
      // setSelANterior(SelResp)
      setSelResp(LoginState.rut)
      setProgramado('Programada')
      // console.log(LoginState.rut)
      setArRecurso([{"rut": '', "minutos": '', "id":0}])
      let f = new Date().toISOString().split('T')[0];
      setProgramadoFecha(f);
    }if(Stado === "En Curso"){
      setProgramado('Programada')
    }else{
      setCantRecurso(0)
      setArRecurso([])
      let f = new Date().toISOString().split('T')[0];
      setInicioJob('');
      setFinJob('');
      setProgramadoFecha(f);
      // setSelResp(SelANterior)
      // setObservaciones('')
    }
  }, [Stado])

  useEffect(() => {
    if(SelMaq === '' || DScrtec === '' || tipo === '' || Turno === '')setIsFullOk(true)
    else setIsFullOk(false)
  }, [SelMaq,DScrtec,Stado,SelResp,checked,minutos,tipo, Turno])
  
  useEffect(() => {
    if(Programado === 'Programada'){
      
      setProgramadoFecha('');
      setSelResp(SelANterior)
    }
    if(Programado === 'En Curso' || Programado === 'Planificada'){
      setSelANterior(SelResp)
      setSelResp(LoginState.rut)
      let f = new Date().toISOString().split('T')[0];
      setProgramadoFecha(f);
    }
    if(Programado !== 'Planificada'){
      setDuration('')
    }
  }, [Programado])

  useEffect(() => {
    const LoadUsersFromFracttal = async () => {
      let query = await fetch(URL_SRV_RAIZ+'getuser_fracttal_planta',{//'getuser_fracttal',{
        method: "POST",
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          PLANTA: LoginState.planta.toUpperCase()
        })
      })

      let responsio = await query.json();
      // console.log(responsio.item)
      setUserListFracttal(responsio.item)
    }
    LoadUsersFromFracttal()

    if(Stado === 'Finalizado'){
      // setSelANterior(SelResp)
      setSelResp(LoginState.rut)
      // console.log(LoginState.rut)
      
      setArRecurso([{"rut": '', "minutos": '', "id":0}])
      let f = new Date().toISOString().split('T')[0];
      setProgramadoFecha(f);
    }else{
      setCantRecurso(0)
      setArRecurso([])
      let f = new Date().toISOString().split('T')[0];
      setInicioJob('');
      setFinJob('');
      setProgramadoFecha(f);
      // setSelResp(SelANterior)
      // setObservaciones('')
    }

  }, [])
  
  //agrega Recurso a array
  useEffect(() => {
    // console.log('Cant Recurso',CantRecurso)
    for (let cbr = 0; cbr < CantRecurso; cbr++) {
      setArRecurso([...ArRecurso,{"rut": '', "minutos": '', "id":cbr+1}])
      // console.log('||=>',cbr+1)
    }
  }, [CantRecurso])
  
  useEffect(() => {
    // console.log('ArRecurso', ArRecurso)
  }, [ArRecurso])
  

  return (
    <>
      <HeaderMenu Pag_Sel={'entrega_turno'} VisibleModal={VisibleModal} setVisibleModal={setVisibleModal} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-12'>
            <h5 className='lead'>Ingreso Orden de Trabajo Correctiva</h5>
          </div>

          <div className='col-12 col-md-6 autocenter'>
            <div className='col-12 card_form'>
              <label>Busque y Seleccione Maquina</label>
              <AsyncSelect isClearable isSearchable  placeholder={'Buscar Equipo'} onChange={(e)=>setSelMaq(e?e:'')} loadOptions={promiseOptionsEquipos} />
            </div>
          </div>
          <div className='col-12 col-md-6 autocenter'>
            <div className='col-12 card_form'>
              <label>Descripcion Tecnica</label>
              <TextField
                style={{backgroundColor: '#fff'}}
                fullWidth
                size='small'
                id="outlined-textarea"
                // label="Descripcion Tecnica"
                placeholder="Ingrese una breve descripcion tecnica..."
                multiline={true}
                value={DScrtec}
                onChange={(e)=>setDScrtec(e.target.value)}
              />
            </div>
          </div>
          <div className='col-12 col-md-6 autocenter'>
            <div className='col-12 card_form'>
              <label>Seleccione tipo OTC</label>
              <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label" size='small'>Selecione Maquina o Equipo</InputLabel> */}
              <Select
                style={{backgroundColor: '#fff'}}
                size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // label="Selecione Maquina o Equipo"
                value={tipo}
                onChange={(e)=>setTipo(e.target.value)}
              >
                <MenuItem value={"ELECTRICA"}>Electrica</MenuItem>
                <MenuItem value={"GASFITER"}>Gasfiter (Neumatica - Hidraulica)</MenuItem>
                <MenuItem value={"MECANICA"}>Mecanica</MenuItem>
                <MenuItem value={"REDES"}>Redes</MenuItem>
                <MenuItem value={"SOLDADOR"}>Soldadura (Vapor - Otros)</MenuItem>
                <MenuItem value={"SUMINISTROS"}>Refrigeracion</MenuItem>
              </Select>
              </FormControl>
            </div>
          </div>
          <div className='col-12 col-md-6 text-center'>
            <div className='col-12 card_form'>
              <label>Estado</label><br/>
              <FormControl>
                {/* <FormLabel id="demo-radio-buttons-group-label">Estado</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="Pendiente"
                  name="radio-buttons-group"
                  value={Stado}
                  onChange={(e)=>setStado(e.target.value)}
                >
                  <Tooltip title="Es una OT para el turno siguiente." placement="top-start" TransitionComponent={Zoom} arrow>
                    <FormControlLabel value="Abierta" control={<Radio />} label="Abierta" />
                  </Tooltip>
                  <FormControlLabel value="Finalizado" control={<Radio />} label="Ejecutado sin Repuesto" />
                  <Tooltip title="Mantenimiento Inmediato." placement="top-start" TransitionComponent={Zoom} arrow>
                    <FormControlLabel value="En Curso" control={<Radio />} label="En Ejecución" />
                  </Tooltip>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className={Stado==="Finalizado"?'col-12 autocenter':'ocultacion'}>
            <div className='col-12  card_form'>
              <div className='row'>
                <div className='col-12'>
                  <label>Recursos Humanos</label>
                </div>
                <div className='col-12'>
                  <button className='btn btn-sm btn-success mb-2' onClick={()=>setCantRecurso(CantRecurso+1)}>Agregar Recurso Humano +</button>                
                </div>

                {
                  ArRecurso?.map((al,index)=>
                    <div className='col-12 divider-card mt-3 pb-2 border-top border-secondary rounded-top' key={al.id}>
                      <div className='row'>
                        <div className='col-12 col-md-6'>
                          <label>Seleccione Recurso Humano</label>
                          <FormControl fullWidth>
                            <Select
                              style={{backgroundColor: '#fff'}}
                              size='small'
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={ArRecurso[index].rut}
                              onChange={(e)=>ChangeValueUsrFttl(e,al.id)}
                            >                           
                              {
                                UserListFracttal.map(usrfttl=>
                                  <MenuItem style={{fontFamily: 'monospace',fontSize: '10'}}  key={usrfttl.value} value={usrfttl.value}><small>{usrfttl.label}</small></MenuItem>                                  
                                )
                              }
                            </Select>
                          </FormControl>
                        </div>
                        <div className='col-12 col-md-6'>
                          <div className='row'>
                            <div className='col-7 '>
                              <label>Minutos</label>
                              <input type='number' value={ArRecurso[index].minutos} onChange={(e)=>ChangeValueminits(e,al.id)} className='form-control' />
                            </div>
                            <div className='col-5 text-center pt-2'>
                              <br/>
                              <button className='btn btn-danger' onClick={()=>DeleteItemFromRECURSOS(al.id)}>Eliminar</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
                
              </div>
            </div>
          </div>
          
          {/* rango fecha inicio fin */}
          <div className={Stado==="Finalizado"?'col-12 autocenter':'ocultacion'}>
          <div className='col card_form'>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <label>Fecha y Hora Inicio Trabajo</label>
                <TextField
                  type='datetime-local'
                  style={{backgroundColor: '#fff'}}
                  fullWidth
                  size='small'
                  id="outlined-textarea"
                  value={InicioJob}
                  onChange={(e)=>setInicioJob(e.target.value)}
                />
              </div>
              <div className='col-12 col-md-6'>
                <label>Fecha y Hora Fin de Trabajo</label>
                <TextField
                  type='datetime-local'
                  style={{backgroundColor: '#fff'}}
                  fullWidth
                  size='small'
                  id="outlined-textarea"
                  value={FinJob}
                  onChange={(e)=>setFinJob(e.target.value)}
                />
              </div>
            </div>
            </div>
          </div>

          
          <div className={Stado==="Abierta"?'col-12 col-md-6 autocenter':'ocultacion'}>
            <div className='col-12 card_form text-center'>
              <FormControl>
                {/* <FormLabel id="demo-radio-buttons-group-label">Estado</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  // defaultValue="Abierta"
                  name="radio-buttons-group"
                  value={Programado}
                  onChange={(e)=>setProgramado(e.target.value)}
                >
                  {/* <FormControlLabel value="Inmediata" control={<Radio />} label="Inmediata" /> */}
                  <FormControlLabel value="Programada" control={<Radio />} label="Programada" />
                  <FormControlLabel value="Planificada" control={<Radio />} label="Planificada" />
                </RadioGroup>
              </FormControl>
              <div className='col-12'>
                <div className='row'>
                  <div className={'col-6'}>
                    <label></label>
                    <TextField
                      focused
                      label={'Fecha'}
                      inputProps={Programado==='Inmediata'?{}:{min:new Date().toISOString().split('T')[0]}}
                      disabled={Programado==='Inmediata'?true:false}
                      type='date'
                      style={{backgroundColor: '#fff'}}
                      fullWidth
                      size='small'
                      id="outlined-textarea"
                      value={ProgramadoFecha}
                      onChange={(e)=>setProgramadoFecha(e.target.value)}
                    />
                  </div>
                  <div className={'col-6'}>
                    <TextField
                      label={'Minutos'}
                      type='number'
                      style={{backgroundColor: '#fff'}}
                      fullWidth
                      size='small'
                      id="outlined-textarea"
                      value={Duration}
                      onChange={(e)=>setDuration(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              
              
              
            </div>
          </div>

          <div className={Stado==="Abierta"?Programado==="Programada"?'col-12 col-md-6 autocenter':'ocultacion':'ocultacion'}>
            <div className='col-12 card_form'>
              <label>Seleccione Recurso Humano</label>
              {/* <FormControl fullWidth>
                <Select
                  style={{backgroundColor: '#fff'}}
                  size='small'
                  value={SelResp}
                  onChange={(e)=>setSelResp(e.target.value)}
                >                           
                  {
                    UserListFracttal.map(usrfttl=>
                      <MenuItem style={{fontFamily: 'monospace',fontSize: '10'}}  key={usrfttl.value} value={usrfttl.value}><small>{usrfttl.label}</small></MenuItem>                                  
                    )
                  }
                </Select>
              </FormControl> */}
              <input type='search' className='form-control' list={"Nombres"} onChangeCapture={(e)=>setSelResp(e.target.value)} onChange={(e)=>promiseOptionsNombre(e.target.value)}/>
              <datalist id="Nombres">
                {
                  UserListFracttalList?.map(asx => <option key={asx.value} value={asx.value+"_ "+asx.label}>{asx.label}</option>)
                }
              </datalist>
              {/* <AsyncSelect isClearable isSearchable  placeholder={'Buscar Equipo'} onChange={(e)=>setSelResp(e?e:'')} loadOptions={promiseOptionsNombre} /> */}
            </div>
          </div>

          <div className={'col-12 col-md-6 autocenter'}>
            <div className='col-12 card_form'>
              <label>Observaciones</label>
              <TextField
                style={{backgroundColor: '#fff'}}
                fullWidth
                size='small'
                id="outlined-textarea"
                // label="Descripcion Tecnica"
                placeholder="Ingrese una observacion . .. . ..."
                multiline={true}
                value={observaciones}
                onChange={(e)=>setObservaciones(e.target.value)}
              />
            </div>
          </div>

          <div className='col-12 col-md-6 autocenter'>
            <div className='col-12 card_form'>
              <label>Seleccione Turno</label>
              <FormControl fullWidth>
              {/* <InputLabel id="demo-simple-select-label" size='small'>Selecione Maquina o Equipo</InputLabel> */}
              <Select
                style={{backgroundColor: '#fff'}}
                size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // label="Selecione Maquina o Equipo"
                value={Turno}
                onChange={(e)=>setTurno(e.target.value)}
              >
                <MenuItem value={"MAÑANA"}>MAÑANA</MenuItem>
                <MenuItem value={"TARDE"}>TARDE</MenuItem>
                <MenuItem value={"NOCHE"}>NOCHE</MenuItem>
              </Select>
              </FormControl>
            </div>
          </div>
          
          <div className='col-12 col-md-6 text-center autocenter'>
            <div className='col-12 card_form'>
              <label>Detencion NO/SI</label><br/>
              <label>Sin Detencion</label>
              <Switch
                color='info'
                icon={<CheckCircleIcon color='success'/>}
                checkedIcon={<CancelIcon color='error'/>}
                checked={checked}
                onChange={(e)=>setChecked(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <label>Detencion</label>
            </div>
          </div>
          <div className={checked?'col-12 col-md-6 autocenter':'ocultacion'}>
            <div className='col-12 card_form'>
              <label>Minutos Detencion</label>
              <TextField
                style={{backgroundColor: '#fff'}}
                fullWidth
                type='number'
                // label="Minutos de Detencion"
                id="outlined-size-small"
                size="small"
                value={minutos}
                onChange={(e)=>setMinutos(e.target.value)}
              />
            </div>
          </div>
          <div className='col-12 pt-4'>
            <div className='col-12 text-center'>
              <Button disabled={IsFullOk} variant="contained" onClick={()=>SendData()} >Enviar Solicitud de OT Correctiva<span style={{marginLeft:15}}> </span> <ChecklistRtlIcon /></Button>
            </div>
          </div>

          {
            ModalLoadded?<ModalLoaded texto={'En estos momentos se enta enviando la solicitud de OT Correctiva, por favor espere!!'} />:null
          }

          <div style={VisibleModal?{display:'block',position: 'fixed',top: '0px',left: '0px',width:'100%', height: '100vh', backgroundColor: 'rgb(0,0,0,0.8)',zIndex:'100000000000000000'}:{display:'none'}}>
            <ModalMakeMeAFileByDate VisibleModal={VisibleModal} setVisibleModal={setVisibleModal} />
          </div>
        </div>
        
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        
      </div>
    </>
    
  )
}



export default Home