import React from 'react'
import { URL_GET_SUB_TASKS } from '../router/Url';

export const GetSubTasks = ({subtask_id}) => {
  // console.log(wofolio);
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_GET_SUB_TASKS+subtask_id,{
      method: 'GET',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    let responsito = await query.json();
    // console.log(responsito);

    // if(responsito.data.success){
      resolve(responsito)
    // }else{
    //   resolve(responsito.data)
    // }
  })
}
