import { URL_STATUS_TO_REVCN } from "../router/Url";


export const StatusToRevcn = async (data) => {
  console.log(data)

  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_STATUS_TO_REVCN,{
      method: 'PUT',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    let responsito = await query.json();
    console.log(responsito);
    if(responsito.success){
      resolve(responsito)
    }else{
      resolve(responsito.success)
    }
  })
 
}
